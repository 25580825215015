import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import { UtilityService } from '@services/utility.service';
import {Observable} from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailComponent } from '@pages/email/email.component';
import { ApiService } from '@services/api.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
// elevation-4
const BASE_CLASSES = '';
// main-sidebar 
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    getList: any;
    userType: string;
    user_type:any
    userToken:any
    currentUrl: string;
    

    constructor(
        public appService: AppService,
        public apiService:ApiService,
        private _utilityService:UtilityService,
        private store: Store<AppState>,
        private modalService : NgbModal,
        private activeRoute: Router
    ) {
        this.activeRoute.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.currentUrl = event.urlAfterRedirects;
        });
    }

    ngOnInit() {
        // superuser_zc
        this.user = this.appService.loginUser;
        this.userToken = this.apiService.decodeAuth2(); 
        this.user_type = this.userToken.role
        console.log('this.user_type',this.user_type)

        this.getList = this._utilityService.decodeUserCode();
     
        this.userType = this.getList.role;
     }

    showPlantList(){
        this._utilityService.setUrl(false);
    }

    openEmailModal() {
        //debugger;
        const modalRef = this.modalService.open(EmailComponent);
        // You can pass data to the modal component if needed
        // modalRef.componentInstance.someData = data;
      }
      isMainMenuActive(submenuUrls: string[]): boolean {
        if (submenuUrls.length === 1 && submenuUrls[0] === '/') {
            return this.currentUrl === '/';
          }
          
        return submenuUrls.some(url => this.currentUrl.includes(url));
      }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/']
    },
    {
        name: 'Plants',
        iconClasses: 'ion-social-windows-outline',
        path: ['/plants']
    },
    
];
