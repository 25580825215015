import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { SubCategoryModel } from '@/model/subcategory';
import { CategoryModel } from '@/model/catlist';
import { SourceModel } from '@/model/source';
import { PriorityModel } from '@/model/priority';
import { TicketListModel } from '@/model/ticketList';
import { CategoryByIdModel } from '@/model/categoryById';
import { PlantListModel } from '@/model/plantList';
import { NocUserModel } from '@/model/nocUser';
import {UserProfile} from '../Interface/userProfile'

@Injectable({
    providedIn: 'root'
})
export class AppService {
    jwtTokan: string;

    // ************************* Apart from ZC - Practice api **********************
    stripIntegration(id:number){
        return this.http.get<any>(
            'http://localhost:8000/create-checkout-session/' + id + '/'
        )
    }
    //  *****************************************************************************

    // public user: any = null;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('tokan')
        })
    };
    // Live Base URL
    

    baseURL = 'https://api.zerocarbon.com.pk'; 
    
    private profileImageSubject = new BehaviorSubject<string | null>(null);
    profileImage$ = this.profileImageSubject.asObservable();
    // Staging Base URL
    // baseURL = 'https://staging.zerocarbon.com.pk';

    getDecodedTokan: any;
    constructor(
        private router: Router,
        private http: HttpClient,
        private _serviceApi: ApiService
    ) { }

    updateUserProfilePic(imageSrcBase64 : string)
    {
        this.profileImageSubject.next(imageSrcBase64);
    }

    loginUser(Username: string, Password: string): Observable<any> {
        return this.http
            .post<any>(
                this.baseURL + '/login',
                { username: Username, password: Password },
                this.httpOptions
            )
            .pipe(
                map((user) => {
                    this.jwtTokan = user.data.access_token;
                    localStorage.setItem('tokan', this.jwtTokan);
                    this.getDecodedTokan = this._serviceApi.decodeAuth();
                })
            );
    }

    setUserInfoApi(username, password) {
        return this.http.post<any>(
            this.baseURL + '/login',
            { username: username, password: password },
            this.httpOptions
        );    }

    dashboard(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getDashboardDetails',
            this.httpOptions
        );
    }

    GetPlantStatus(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getPlantsStatus',
            this.httpOptions
        );
    }

    GetPlantLocation(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getStationsLocation',
            this.httpOptions
        );
    }

    

    GetAlarmStatus(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getAlarmsAndStatusCount',
            this.httpOptions
        );
    }

    GetPlantAlarmCountAPI(StationCode : any): Observable<any> {
        return this.http.get(
            this.baseURL + '/getAlarmsCount?code=' + StationCode,
            this.httpOptions
        );
    }
    getMonthRoiGraph(StationCode : any): Observable<any> {
        return this.http.get(
            this.baseURL + '/getMonthlyRoiGraph?code=' + StationCode,
            this.httpOptions
        );
    }
    getYearlyRoiGraph(StationCode : any): Observable<any> {
        return this.http.get(
            this.baseURL + '/getYearlyRoiGraph?code=' + StationCode,
            this.httpOptions
        );
    }

  
    PlantsdetailsAllApi(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getAllPlants',
            this.httpOptions
        );
    }

    graphDetails(): Observable<any> {
        return this.http.get(this.baseURL + '/getPlantsCity', this.httpOptions);
    }
    Plantsdetails_old(): Observable<any> {
        return this.http.get(this.baseURL + '/getPlantsList', this.httpOptions);
    }
    Plantsdetails(): Observable<any> {
        return this.http.get(this.baseURL + '/plantListPortal', this.httpOptions);
    }
    // -------------- ADDED BY EMAN ---------------
    getAllPlantsdetails(search:string,page:number,size:number,sort: string, column: string,type: string, status: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          });
          console.log('search',search)
        const queryParams: any = {};
        //   if (search!==null) {
        //     console.log('inside search',search)
        //     queryParams['search'] = search;
        //   }
        //   if (page !== 1) {
        //     queryParams['page'] = page;
        //   }
        //   if (size !== 10) {
        //     queryParams['size'] = size;
        //   }
        //   if (sort!==null && column!=null) {
        //     queryParams['sort'] = sort;
        //     queryParams['column'] = column;
        //   }
        //   if (type !== null) {
        //     queryParams['type'] = type;
        // }
        // if (status !== null) {
        //     queryParams['status'] = status;
        // }
        // Set name if provided
        if (search && search.trim() !== '') {
            queryParams['search'] = search;
            if (search !== '') {
            delete queryParams['page'];
            delete queryParams['size'];
            delete queryParams['type'];
            delete queryParams['status'];
            }
        } else {
            // Set page if greater than 1 or if size is provided
            if (page > 1) {
            queryParams['page'] = page;
            }
    
            // Set size if greater than 10
            if (size && size > 10) {
            queryParams['size'] = size;
            }
            if (sort!==null && column!=null) {
                    queryParams['sort'] = sort;
                    queryParams['column'] = column;
            }
    
            // Set department_id if provided
            if (type !== null) {
            queryParams['type'] = type;
            }
    
            // Set designation_id if provided
            if (status !== null) {
            queryParams['status'] = status;
            }
        }
            return this.http
            .get(
                `${this.baseURL}/plantListPortal` , {
                    headers,
                    params: queryParams,
                  }
            )
    }
    //  ---------------- End ------------------------
    FilteredPlantsdetails(filter : string): Observable<any> {
        return this.http.get(this.baseURL + '/plantListPortal?' + filter, this.httpOptions);
    }
    chanePasswordApi(changePass): Observable<any> {
        return this.http.post(this.baseURL + '/changePassword',changePass, this.httpOptions);
    }
    sendForgotApi(forgotPassword): Observable<any> {
        return this.http.post(this.baseURL + '/forgotPassword',forgotPassword, this.httpOptions);
    }
    saveUserApi(userList): Observable<any> {
        return this.http.post(this.baseURL + '/createAccount',userList, this.httpOptions);
    }
    setChangePasswordApi(ChangePassword): Observable<any> {
        return this.http.post(this.baseURL + '/resetPassword',ChangePassword, this.httpOptions);
    }
    getNormalizePowerGraph(stationCode : any): Observable<any> {
        return this.http.post(this.baseURL + '/getNormalizePowerGraph',stationCode, this.httpOptions);
    }
    getAvailabilityGraph(stationCode : any): Observable<any> {
        return this.http.post(this.baseURL + '/getAvailabilityChart',stationCode, this.httpOptions);
    }
    plantStatus(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getPlantsStatus',
            this.httpOptions
        );
    }
    setWeatherUpdatesApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL + '/getWeather?code=' + getStationCode,
            this.httpOptions
        );
    }
    getpowerFlowListApi(getStationCode): Observable<any> {
        return this.http.get(
            //this.baseURL + '/getPowerFlow?code=' + getStationCode, old API
            this.baseURL + '/newMobilePowerFlow?code=' + getStationCode,
            this.httpOptions
        );
    }

    getHeatMapListApi(getStationCode): Observable<any> {
        return this.http.get(
            //this.baseURL + '/getPowerFlow?code=' + getStationCode, old API
            this.baseURL + '/getInverterHeatmap?code=' + getStationCode,
            this.httpOptions
        );
    }

    pvgetDataApi_old(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/getCurrentPVValuesByInverterId?code=' +
            getStationCode ,
            this.httpOptions
        );
    }

    pvgetDataApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/getCurrentPVValuesByInverterId?code=' +
            getStationCode ,
            this.httpOptions
        );
    }

    setgetPlantDetailsApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL + '/getPlantDetails?code=' + getStationCode,
            this.httpOptions
        );
    }
    countAlertlistApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL + '/getAlarmsCount?code=' + getStationCode,
            this.httpOptions
        );
    }
    newPvGraphsListApi(devId, dt): Observable<any> {
        return this.http.get(
            this.baseURL + '/newPvGraphs?devId=' + devId + '&collectTime=' + dt,
            this.httpOptions
        );
    }

    
    receiveAlarmbyIDApi(getAlarm): Observable<any> {
        return this.http.get(
            this.baseURL + '/getAlarmById?id=' + getAlarm,
            this.httpOptions
        );
    }
    getEnvBnifitsApi(getStationCode, dt, getDayRang): Observable<any> {
        return this.http.get(
            this.baseURL +
            //'/getEnvironmentalBenefit?code=' +
            '/newMobileGetEnvironmentalBenefit?code=' +
            getStationCode +
            '&time=' +
            dt +
            '&type=' +
            getDayRang,
            this.httpOptions
        );
    }
    getMonthChartApi_old(getStationCode, dt): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/getMonthChart?code=' +
            getStationCode +
            '&time=' +
            dt,
            this.httpOptions
        );
    }
    getMonthChartApi(getStationCode, dt): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/newMobilePlantGraphMonthly?code=' +
          //'/getMonthChart?code=' +
            getStationCode +
            '&time=' +
            dt,
            this.httpOptions
        );
    }

    getYearChartApi(getStationCode, dt): Observable<any> {
        return this.http.get(
            this.baseURL +
           // '/getYearChart?code=' +
           '/newMobilePlantGraphYearly?code=' +
            getStationCode +
            '&time=' +
            dt,
            this.httpOptions
        );
    }
    // Added by Eman
    getLiftTimeChartApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL +
           '/newMobilePlantGraphLifetime?code=' +
            getStationCode,
            this.httpOptions
        );
    }
    // ----------------------------- end ------------------
    logout() {
        localStorage.removeItem('tokan');
        localStorage.removeItem('gatekeeper_token');
        this.loginUser = null;
        this.router.navigate(['/login']);
    }

    setfiveMinDataApi_old(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL + '/fiveMinData?code=' + getStationCode,
            this.httpOptions
        );
    }

    setfiveMinDataApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL + '/getPlantGenerationAndConsumptionDaily?code=' + getStationCode ,
            this.httpOptions
        );
    }
    setfiveMinDataApiWithDate_old(getStationCode, dtEnv): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/fiveMinData?code=' +
            getStationCode +
            '&time=' +
            dtEnv,
            this.httpOptions
        );
    }

    setfiveMinDataApiWithDate(getStationCode, dtEnv): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/getPlantGenerationAndConsumptionDaily?code=' +
            getStationCode +
            '&time=' +
            dtEnv,
            this.httpOptions
        );
    }
    
    setEmiGraphApiWithDate(getStationCode, dtEnv): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/getEmiGraph?code=' +
            getStationCode +
            '&time=' +
            dtEnv,
            this.httpOptions
        );
    }
    inverterPrimeDataApi_old(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/getInvertersStats?code=' +
            getStationCode ,
            this.httpOptions
        );
    }

    inverterPrimeDataApi(getStationCode): Observable<any> {
        return this.http.get(
            this.baseURL +
            '/newMobileInvertersList?code=' +
            getStationCode ,
            this.httpOptions
        );
    }
   
    sendSubCatApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getSubCategoryList', this.httpOptions)
            .pipe(
                map((res) => {
                    const subcats = res && res['data'];
                    return subcats.map((e) => new SubCategoryModel(e));
                })
            );
    }

    receiveCategoryApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getCategoryList', this.httpOptions)
            .pipe(
                map((res) => {
                    const category = res && res['data'];
                    return category.map((e) => new CategoryModel(e));
                })
            );
    }

    receiveSourceApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getAllSource', this.httpOptions)
            .pipe(
                map((res) => {
                    const source = res && res['data'];
                    return source.map((e) => new SourceModel(e));
                })
            );
    }

    receivePreorityApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getPriorityList', this.httpOptions)
            .pipe(
                map((res) => {
                    const priortyVar = res && res['data'];
                    return priortyVar.map((e) => new PriorityModel(e));
                })
            );
    }

    // receiveTicketListApi(): Observable<any> {

    //     //return this.http.get(this.baseURL + '/getAllComplaints', this.httpOptions);

    //     return this.http
    //         .get(this.baseURL + '/getAllComplaints', this.httpOptions)
    //         .pipe(
    //             map((res) => {
    //                 const tLVar = res && res['data'];
    //                 const ticketList = tLVar.map((e) => new TicketListModel(e));
    //                 // Return the modified response
    //                 return {
    //                     ...res,
    //                     data: ticketList, 
    //                 };
    //              })
    //         );
    // }
    receiveTicketListApi(search:string): Observable<any> {

        //return this.http.get(this.baseURL + '/getAllComplaints', this.httpOptions);

        return this.http
            .get(this.baseURL + '/getAllComplaints?search='+ search, this.httpOptions)
            .pipe(
                map((res) => {
                    const tLVar = res && res['data'];
                    const ticketList = tLVar.map((e) => new TicketListModel(e));
                    // Return the modified response
                    return {
                        ...res,
                        data: ticketList, 
                    };
                 })
            );
    }
    // ----------------- Added by Eman --------------------------
    receiveAllTicketListApi(search:string,page:number,size:number,sort:string,column:string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          });
          console.log('search',search)
        const queryParams: any = {};
       
        // if (search && search.trim() !== '') {
        //     queryParams['search'] = search;
        //     if (search !== '') {
        //       delete queryParams['page'];
        //       delete queryParams['size'];
        //     }
        //   } else {
        //     // Set page if greater than 1 or if size is provided
        //     if (page > 1) {
        //       queryParams['page'] = page;
        //     }
      
        //     // Set size if greater than 10
        //     if (size && size > 10) {
        //       queryParams['size'] = size;
        //     }
        //     if (sort!==null && column!=null) {
        //             queryParams['sort'] = sort;
        //             queryParams['column'] = column;
        //     }
        //   }
        if (search && search.trim() !== '') {
            queryParams['search'] = search;
            if (search !== '') {
                if(page > 1){
                  queryParams['page'] = page;
                  if(size > 10) {
                    queryParams['size'] = size
                  }
                  else{
                    delete queryParams['size'];
                  }
                }
                else{
                  if(size){
                    queryParams['size'] = size
                  }
                  delete queryParams['page'];
                }
              }
        } else {
            // Set page if greater than 1 or if size is provided
            if (page > 1) {
            queryParams['page'] = page;
            }
    
            // Set size if greater than 10
            if (size && size > 10) {
            queryParams['size'] = size;
            }
            if (sort!==null && column!=null) {
                    queryParams['sort'] = sort;
                    queryParams['column'] = column;
            }
        }
        return this.http
            .get(
                `${this.baseURL}/getAllComplaints` , {
                    headers,
                    params: queryParams,
                  }
            )
    }
    //  -------------------- Added By Eman -------------------
    FilterRecvieTicketListApi(filter : string): Observable<any> {
        return this.http
            .get(this.baseURL + '/getAllComplaints?'+ filter, this.httpOptions)
            .pipe(
                 map((res) => {
                    const tLVar = res && res['data'];
                    const ticketList = tLVar.map((e) => new TicketListModel(e));
                    // Return the modified response
                    return {
                        ...res,
                        data: ticketList, 
                    };
                 })
            );
    }


    recvieTicketListApi(ticektId): Observable<any> {
        return this.http
            .get(this.baseURL + '/getComplaintByTicketId?ticket_id=' + ticektId, this.httpOptions)
            .pipe(
                map((res) => {
                    const tLVar = res && res['data'];
                    return tLVar.map((e) => new TicketListModel(e));
                })
            );
    }
    receiveUserApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getAllUsers', this.httpOptions)
            .pipe(
                // map((res) => {
                //     const userVar = res && res['data'];
                //     return userVar.map((e) => new UserModel(e));
                // })
            );
    }
receiveAllUsers(search: string, page: number, size: number, sort: string | null, column: string | null,user_type:number): Observable<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
    console.log('search', search);
    let queryParams: any = {};
    if (search && search.trim() !== '') {
        queryParams['search'] = search;
        if (search !== '') {
            if(page > 1){
              queryParams['page'] = page;
              if(size > 10) {
                queryParams['size'] = size
              }
              else{
                delete queryParams['size'];
              }
            }
            else{
              if(size){
                queryParams['size'] = size
              }
              if (user_type != null) {
                queryParams['user_type'] = user_type;
              } else {
                delete queryParams['user_type'];
              }
              delete queryParams['page'];
            }
          }
    } else {
        // Set page if greater than 1 or if size is provided
        if (page > 1) {
        queryParams['page'] = page;
        }

        // Set size if greater than 10
        if (size && size > 10) {
        queryParams['size'] = size;
        }
        if (sort!==null && column!=null) {
                queryParams['sort'] = sort;
                queryParams['column'] = column;
        }

        // Set department_id if provided
        if (user_type !== null) {
        queryParams['user_type'] = user_type;
        }
    }
    return this.http.get(`${this.baseURL}/getAllUsers`, {
        headers,
        params: queryParams,
    });
}
receiveInactiveUsers(search: string, page: number, size: number, sort: string | null, column: string | null,user_type:number): Observable<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
    console.log('search', search);
    let queryParams: any = {};
    if (search && search.trim() !== '') {
        queryParams['search'] = search;
        if (search !== '') {
            if(page > 1){
              queryParams['page'] = page;
              if(size > 10) {
                queryParams['size'] = size
              }
              else{
                delete queryParams['size'];
              }
            }
            else{
              if(size){
                queryParams['size'] = size
              }
              if (user_type != null) {
                queryParams['user_type'] = user_type;
              } else {
                delete queryParams['user_type'];
              }
              delete queryParams['page'];
            }
          }
    } else {
        // Set page if greater than 1 or if size is provided
        if (page > 1) {
        queryParams['page'] = page;
        }

        // Set size if greater than 10
        if (size && size > 10) {
        queryParams['size'] = size;
        }
        if (sort!==null && column!=null) {
                queryParams['sort'] = sort;
                queryParams['column'] = column;
        }

        // Set department_id if provided
        if (user_type !== null) {
        queryParams['user_type'] = user_type;
        }
    }
    return this.http.get(`${this.baseURL}/getInactiveUsers`, {
        headers,
        params: queryParams,
    });
}


receiveAllTraiffTypes(search: string, page: number, page_size: number, sort: string | null, column: string | null): Observable<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
    console.log('search', search);
    let queryParams: any = {};
    if (search && search.trim() !== '') {
        queryParams['search'] = search;
        if (search !== '') {
            if(page > 1){
              queryParams['page'] = page;
              if(page_size > 10) {
                queryParams['page_size'] = page_size
              }
              else{
                delete queryParams['page_size'];
              }
            }
            else{
              if(page_size){
                queryParams['page_size'] = page_size
              }
              delete queryParams['page'];
            }
          }
    } else {
        // Set page if greater than 1 or if size is provided
        if (page > 1) {
        queryParams['page'] = page;
        }

        // Set size if greater than 10
        if (page_size && page_size > 10) {
        queryParams['page_size'] = page_size;
        }
        if (sort!==null && column!=null) {
                queryParams['sort'] = sort;
                queryParams['column'] = column;
        }
    }
    return this.http.get(`${this.baseURL}/getAllTariffTypes`, {
        headers,
        params: queryParams,
    });
}
receivedropdownTraiffTypes(): Observable<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
    return this.http.get(`${this.baseURL}/getAllTariffTypesForDropDown`, {headers});
}

    addTariffTypes(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/addEditTariffType`,body,this.httpOptions)
    }
    editTariffTypes(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/addEditTariffType`,body,this.httpOptions)
    }
    deleteTariffTypes(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/deleteTariffType`,body,this.httpOptions)
    }
    receiveAllTraiffCompanies(search: string, page: number, page_size: number, sort: string | null, column: string | null): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        });
        console.log('search', search);
        let queryParams: any = {};
        if (search && search.trim() !== '') {
            queryParams['search'] = search;
            if (search !== '') {
                if(page > 1){
                  queryParams['page'] = page;
                  if(page_size > 10) {
                    queryParams['page_size'] = page_size
                  }
                  else{
                    delete queryParams['page_size'];
                  }
                }
                else{
                  if(page_size){
                    queryParams['page_size'] = page_size
                  }
                  delete queryParams['page'];
                }
              }
        } else {
            // Set page if greater than 1 or if size is provided
            if (page > 1) {
            queryParams['page'] = page;
            }
    
            // Set size if greater than 10
            if (page_size && page_size > 10) {
            queryParams['page_size'] = page_size;
            }
            if (sort!==null && column!=null) {
                    queryParams['sort'] = sort;
                    queryParams['column'] = column;
            }
        }
        return this.http.get(`${this.baseURL}/getAllTariffCompanies`, {
            headers,
            params: queryParams,
        });
    }
    receivedropdownCompanyTariff(): Observable<any> {
        return this.http.get(
            this.baseURL + '/getAllTariffCompaniesForDropDown',
            this.httpOptions
        );
    }

    addTariffCompany(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/addEditTariffCompany`,body,this.httpOptions)
    }
    editTariffCompany(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/addEditTariffCompany`,body,this.httpOptions)
    }
    deleteTariffCompany(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/deleteTariffCompany`,body,this.httpOptions)
    }
    receiveAllPlantTariffs(search: string, page: number, page_size: number, 
        sort: string | null, column: string | null,
        tariff_company:number | null,
        tariff_type:number | null,
        plant_id:number | null): Observable<any> 
        {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        });
        let queryParams: any = {};
        if (search && search.trim() !== '') {
            queryParams['search'] = search;
            if (search !== '') {
                if(page > 1){
                  queryParams['page'] = page;
                  if(page_size > 10) {
                    queryParams['size'] = page_size
                  }
                  else{
                    delete queryParams['size'];
                  }
                }
                else{
                  if(page_size){
                    queryParams['size'] = page_size
                  }
                  if (tariff_company != null) {
                    queryParams['tariff_company'] = tariff_company;
                  } else {
                    delete queryParams['tariff_company'];
                  }
                  if (tariff_type != null) {
                    queryParams['tariff_type'] = tariff_type;
                  } else {
                    delete queryParams['tariff_type'];
                  }
                  if (plant_id != null) {
                    queryParams['plant_id'] = plant_id;
                  } else {
                    delete queryParams['plant_id'];
                  }
                  delete queryParams['page'];
                }
              }
        } else {
            // Set page if greater than 1 or if size is provided
            if (page > 1) {
            queryParams['page'] = page;
            }
    
            // Set size if greater than 10
            if (page_size && page_size > 10) {
                queryParams['size'] = page_size;
            }
            if (sort!==null && column!=null) {
                    queryParams['sort'] = sort;
                    queryParams['column'] = column;
            }
            if (tariff_company!==null) {
                queryParams['tariff_company'] = tariff_company;
        }
            if (tariff_type!==null) {
                queryParams['tariff_type'] = tariff_type;
        }
            if (plant_id!==null) {
                queryParams['plant_id'] = plant_id;
        }
        }
        return this.http.get(`${this.baseURL}/getPlantTariff`, {
            headers,
            params: queryParams,
        });
    }

    addPlantTariff(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/linkPlantTariff`,body,this.httpOptions)
    }
    editPlantTariff(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/editPlantTariff`,body,this.httpOptions)
    }
    deletePlantTariff(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/deleteTariffCompany`,body,this.httpOptions)
    }

    receiveAllTariffRates(search: string, page: number, page_size: number, 
        sort: string | null, column: string | null,
        tariff_company:number | null,
        tariff_type:number | null,
        year:number | null): Observable<any> 
        {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        });
        console.log('search', search);
        let queryParams: any = {};
        if (search && search.trim() !== '') {
            queryParams['search'] = search;
            if (search !== '') {
                if(page > 1){
                  queryParams['page_size'] = page;
                  if(page_size > 10) {
                    queryParams['page_size'] = page_size
                  }
                  else{
                    delete queryParams['page_size'];
                  }
                }
                else{
                  if(page_size){
                    queryParams['page_size'] = page_size
                  }
                  if (tariff_company != null) {
                    queryParams['tariff_company'] = tariff_company;
                  } else {
                    delete queryParams['tariff_company'];
                  }
                  if (tariff_type != null) {
                    queryParams['tariff_type'] = tariff_type;
                  } else {
                    delete queryParams['tariff_type'];
                  }
                  if (year != null) {
                    queryParams['year'] = year;
                  } else {
                    delete queryParams['year'];
                  }
                  delete queryParams['page'];
                }
              }
        } else {
            // Set page if greater than 1 or if size is provided
            if (page > 1) {
            queryParams['page'] = page;
            }
    
            // Set size if greater than 10
            if (page_size && page_size > 10) {
            queryParams['page_size'] = page_size;
            }
            if (sort!==null && column!=null) {
                    queryParams['sort'] = sort;
                    queryParams['column'] = column;
            }
            if (tariff_company!==null) {
                queryParams['tariff_company'] = tariff_company;
        }
            if (tariff_type!==null) {
                queryParams['tariff_type'] = tariff_type;
        }
            if (year!==null) {
                queryParams['year'] = year;
        }
        }
        return this.http.get(`${this.baseURL}/getTariffRates`, {
            headers,
            params: queryParams,
        });
    }

    addTariffRates(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/addEditTariffRate`,body,this.httpOptions)
    }
    editTariffRates(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/addEditTariffRate`,body,this.httpOptions)
    }
    deleteTariffRates(id:any):Observable<any>{
        return this.http.delete<any>(`${this.baseURL}/deleteTariffRate/${id}/`,this.httpOptions)
    }

    FilterreceiveUserApi(filter : string): Observable<any> {
        return this.http
            .get(this.baseURL + '/getAllUsers?'+ filter, this.httpOptions)
            .pipe(
                // map((res) => {
                //     const userVar = res && res['data'];
                //     return userVar.map((e) => new UserModel(e));
                // })
            );
    }
    receiveNocUserApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getNocUsers', this.httpOptions)
            .pipe(
                map((res) => {
                    const userVar = res && res['data'];
                    return userVar.map((e) => new NocUserModel(e));
                })
            );
    }

    selectCategoryRowApi(sendIdCat): Observable<any> {
        return this.http
            .get(
                this.baseURL + '/getCategoryById?id=' + sendIdCat,
                this.httpOptions
            )
            .pipe(
                map((res) => {
                    const catRow = res && res['data'];
                    return catRow.map((e) => new CategoryByIdModel(e));
                })
            );
    }
    categoryEditApi(categoryEdit): Observable<any> {
        return this.http.post(
            this.baseURL + '/addEditCategory',
            categoryEdit,
            this.httpOptions
        );
    }
    addSubCatApi(getSubcatadd): Observable<any> {
        return this.http.post(
            this.baseURL + '/addEditSubCategory',
            getSubcatadd,
            this.httpOptions
        );
    }
    showLinkPlantApi(userInlist): Observable<any> {
        return this.http.get(
            this.baseURL + '/getPlantOwners?userid='+
            userInlist,
            this.httpOptions
        );
    }

    showLinkUserApi(userInlist): Observable<any> {
        return this.http.get(
            this.baseURL + '/getPlantOwners?userid='+
            userInlist,
            this.httpOptions
        );
    }


    deleteCategoryApi(id): Observable<any> {
        return this.http.post(
            this.baseURL + '/deleteCategory?id=' + id,
            this.httpOptions
        );
    }
    deletePriortyListApi(deletePri): Observable<any> {
        return this.http.post(
            this.baseURL + '/deletePriority?id=' + deletePri,
            this.httpOptions
        );
    }
    deleteSubCategoryApi(deleteSubCat): Observable<any> {
        return this.http.post(
            this.baseURL + '/deleteSubCategory?id=' + deleteSubCat,
            this.httpOptions
        );
    }
    deleteSourceListApi(deleteSource): Observable<any> {
        return this.http.post(
            this.baseURL + '/deleteSource?id=' + deleteSource,
            this.httpOptions
        );
    }
    receiveSubCatDateaPI(catid): Observable<any> {
        return this.http
            .get(
                this.baseURL + '/getSubCategoryById?id=' + catid,
                this.httpOptions
            )
            .pipe(
                map((res) => {
                    const subCatUpdate = res && res['data'];
                    return subCatUpdate.map((e) => new SubCategoryModel(e));
                })
            );
    }

    getPriorityUpdateApi(getPriorityId): Observable<any> {
        return this.http
            .get(
                this.baseURL + '/getPriorityById?id=' + getPriorityId,
                this.httpOptions
            )
            .pipe(
                map((res) => {
                    const getprioryVar = res && res['data'];
                    return getprioryVar.map((e) => new PriorityModel(e));
                })
            );
    }
    getSourceByidApi(getUpdateId): Observable<any> {
        return this.http
            .get(
                this.baseURL + '/getSourceById?id=' + getUpdateId,
                this.httpOptions
            )
            .pipe(
                map((res) => {
                    const getprioryVar = res && res['data'];
                    return getprioryVar.map((e) => new PriorityModel(e));
                })
            );
    }
    addPriorityApi(priAdd): Observable<any> {
        return this.http.post(
            this.baseURL + '/addEditPriority',
            priAdd,
            this.httpOptions
        );
    }
    linkUserPlantsApi(LinkPlantU): Observable<any> {
        return this.http.post(
            this.baseURL + '/linkPlantOwners',
            LinkPlantU,
            this.httpOptions
        );
    }
    updatePriorityListApi(priUpdateModel): Observable<any> {
        return this.http.post(
            this.baseURL + '/addEditPriority',
            priUpdateModel,
            this.httpOptions
        );
    }
    updateSourceListApi(updateSourceModel): Observable<any> {
        return this.http.post(
            this.baseURL + '/addEditSource',
            updateSourceModel,
            this.httpOptions
        );
    }
    addSourceListApi(addSourceModel): Observable<any> {
        return this.http.post(
            this.baseURL + '/addEditSource',
            addSourceModel,
            this.httpOptions
        );
    }
    addComplainListApi(addComplan): Observable<any> {
        return this.http.post(
            this.baseURL + '/addTicketWeb',
            addComplan,
            this.httpOptions
        );
    }
    getPlantsListApi(): Observable<any> {
        return this.http
            .get(this.baseURL + '/getAllPlants', this.httpOptions)
            .pipe(
                map((res) => {
                    const getPlantList = res && res['data'];
                    return getPlantList.map((e) => new PlantListModel(e));
                })
            );
    }

    getCategoryBySourceIdApi(sourceId): Observable<any> {
        return this.http
            .get(
                this.baseURL + '/getCategoryBySource?source=' + sourceId,
                this.httpOptions
            )
            .pipe(
                map((res) => {
                    const getCBSi = res && res['data'];
                    return getCBSi.map((e) => new CategoryModel(e));
                })
            );
    }
    getSubCategoryByCatIdApi(catId): Observable<any> {
        return this.http
            .get(
                this.baseURL + '/getSubCategoryByCategory?category=' + catId,
                this.httpOptions
            )
            .pipe(
                map((res) => {
                    const getCBSi = res && res['data'];
                    return getCBSi.map((e) => new SubCategoryModel(e));
                })
            );
    }
    // receiveAlarmApi(): Observable<any> {
    //     return this.http
    //         .get(
    //             this.baseURL + '/getAlarmsList' ,
    //             this.httpOptions
    //         )
    // }
    clearAlarms(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/clearAlarms`,body,this.httpOptions)
    }
    unClearAlarms(body:any):Observable<any>{
        return this.http.post<any>(`${this.baseURL}/unclearAlarms`,body,this.httpOptions)
    }
    receiveAlarmApi(): Observable<any> {
        return this.http
            .get(
                this.baseURL + `/getAlarmsList` , this.httpOptions
            )
    }
    // Added By Eman
    receiveAllAlarmApi(search:string,page:number,size:number,sort:string | null ,column:string | null): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          });
          console.log('search',search)
            const queryParams: any = {};
          if (search!==null) {
            console.log('inside search',search)
            queryParams['search'] = search;
          }
          if (page !== 1) {
            queryParams['page'] = page;
          }
          if (size !== 10) {
            queryParams['size'] = size;
          }
          if (sort!==null && column!=null) {
            queryParams['sort'] = sort;
            queryParams['column'] = column;
            }
        // if (search && search.trim() !== '') {
        //     queryParams['search'] = search;
        //     if (search !== '') {
        //     delete queryParams['page'];
        //     delete queryParams['size'];
        //     }
        // } else {
        //     // Set page if greater than 1 or if size is provided
        //     if (page > 1) {
        //     queryParams['page'] = page;
        //     }
    
        //     // Set size if greater than 10
        //     if (size && size > 10) {
        //     queryParams['size'] = size;
        //     }
        //     if (sort!==null && column!=null) {
        //             queryParams['sort'] = sort;
        //             queryParams['column'] = column;
        //     }
        // }
        return this.http
            .get(
                `${this.baseURL}/getAlarmsList` , {
                    headers,
                    params: queryParams,
                  }
            )
    }
    receiveHistoricalAlarm(search:string,page:number,size:number,sort:string|null,column:string|null): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          });
          console.log('search',search)
        const queryParams: any = {};
          if (search!==null) {
            queryParams['search'] = search;
          }
          if (page !== 1) {
            queryParams['page'] = page;
          }
          if (size !== 10) {
            queryParams['size'] = size;
          }
          if (sort!==null && column!=null) {
            queryParams['sort'] = sort;
            queryParams['column'] = column;
            }
        return this.http
            .get(
                `${this.baseURL}/getHistoricalAlarms` , {
                    headers,
                    params: queryParams,
                  }
            )
    }

    receiveAlarmApiFilter(filter : string): Observable<any> {
        console.log('search',filter)
        return this.http
            .get(
                this.baseURL + '/getAlarmsList?search=' + filter ,
                this.httpOptions
            )
    }
    changeStatusApi(priortylist): Observable<any> {
        return this.http.post(
            this.baseURL + '/changeTicketStatus',
            priortylist,
            this.httpOptions
        );
    }
    changePrioritySelectApi(changePrioritySelect): Observable<any> {
        return this.http.post(
            this.baseURL + '/changeTicketPriority',
            changePrioritySelect,
            this.httpOptions
        );
    }
    changeAssignToSendApi(selectUser): Observable<any> {
        return this.http.post(
            this.baseURL + '/changeTicketAssignee',
            selectUser,
            this.httpOptions
        );
    };
  
    removeUserPlantApi(removeLinkPlantList): Observable<any> {
        
        return this.http.post(
            this.baseURL + '/unlinkPlantOwners',
            removeLinkPlantList,
            this.httpOptions
        );
    }
    setInverterDcGrApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getInverterInputDCGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    setNormalizdGraphApi(receivestationResult,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPlantNormalizePowerGraph?code='+
            receivestationResult+'&time='+setDate,
            this.httpOptions
        );
    }
    setInverterOPGrApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getInverterOutputGraph?devId='+
            stData+'&time='+setDate,
            this.httpOptions
        );
    }
    setInverterNOmalPGraPI(stData): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPlantNormalizePowerGraph?code=NE='+
            stData,
            this.httpOptions
        );
    }




    getPvVoltageGraphApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPvVoltageGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    getPVcurrentValuesApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPvCurrentGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    getPVPowerValuesApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPvPowerGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    getBothVoltageCurrentsendApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPvVoltageCurrentGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    getBothVoltagePowersendApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPvVoltagePowerGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    getBothCurrentPowersendApi(stData,setDate): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getPvCurrentPowerGraph?devId='+
            stData +'&time='+setDate,
            this.httpOptions
        );
    }
    selectUserTypeAPI(): Observable<any> {
        
        return this.http.get(this.baseURL + '/getUserTypes',this.httpOptions);
    }


    updateInstallationCostApi(stationCode,installationCost): Observable<any> {
        const formData = new FormData();
        formData.append('code', stationCode);
        formData.append('cost', installationCost.toString());
        return this.http.post(
            this.baseURL + '/updateStation',
            { code: stationCode, cost: parseFloat(installationCost) },
            this.httpOptions
        );
    }
    reCalculateRoiApi(stationCode): Observable<any> {
        return this.http.post(
            this.baseURL + '/reCalculateGraph',
            { code: stationCode },
            this.httpOptions
        );
    }

    predictedRoiApi(requestbody): Observable<any> {
        return this.http.post(
            this.baseURL + '/predictedRoi',
            requestbody,
            this.httpOptions
        );
    }



    getCommentsApi(ticketId): Observable<any> {
        
        return this.http.get(
            this.baseURL + '/getComments?ticket_id='+
            ticketId,
            this.httpOptions
        );
    }

    addCommentsApi(ticketId, comment,files, type): Observable<any> {
        

       var httpOptions2 = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + localStorage.getItem('tokan')
            })
        };

        const formData = new FormData();
        formData.append('ticket_id', ticketId);
    // formData.append('ticket_id', ticketId); // Replace with your form data
    if(type == 1)
    {
        formData.append('comment', comment);
    }
    else
    {

        for (let i = 0; i < files.length; i++) {
            formData.append('media', files[i]);
          }
        // formData.append('media', files);
    }
    

        return this.http.post(
            this.baseURL + '/addComment',
            formData,
            httpOptions2
        );
    }


    disableUserApi(userId):Observable<any> {
        return this.http.post(
            this.baseURL + '/disableUser',
            { user_id: userId},
            this.httpOptions
        );
    }
    enableUserApi(userId):Observable<any> {
        return this.http.post(
            this.baseURL + '/enableUser',
            { user_id: userId},
            this.httpOptions
        );
    }

    getUserProfile(userId : string):Observable<any> {
        return this.http.get(
            this.baseURL + '/getProfile?user_id='+ userId, this.httpOptions);
    }

    updateUserProfile(userId : string, userProfile : UserProfile):Observable<any> {
        return this.http.post(
            this.baseURL + '/updateProfile',
            { user_id: userId, full_name: userProfile.name, contact: userProfile.contact, email : userProfile.email, address : userProfile.address, profile_picture : userProfile.profile_picture },
            this.httpOptions
        );
    }
}
