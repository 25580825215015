import { Alert } from '@/Interface/alert';
import { EnvBanifits } from '@/Interface/envirmentBanifits';
import { HistoryDetail } from '@/Interface/history';
import { PlantDetail } from '@/Interface/plantDetail';
import { PlantList, PlantPower, HeatMapIntterface } from '@/Interface/power';
import { TodayWeather } from '@/Interface/weather';
import { formatDate, JsonPipe, formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilityService } from '@services/utility.service';
import * as Highcharts from 'highcharts';
import { SlickCarouselComponent } from 'ngx-slick-carousel';



import { AlarmStatusInterface } from '@/Interface/plants';
import { ChangeDetectorRef } from '@angular/core';

import { BaseChartDirective } from 'ng2-charts';

import {
    NgbDateStruct,
    NgbCalendar,
    NgbDatepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { InverterDetail } from '@/Interface/inverterDetail';
import { Pv, Voltage } from '@/Interface/pv';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { layouts } from 'chart.js';
import { PredictedRoiService } from '@services/prediction/predicted-roi.service';


@Component({
    selector: 'app-plant-detail',
    templateUrl: './plant-detail.component.html',
    styleUrls: ['./plant-detail.component.scss']
})
export class PlantDetailComponent implements OnInit {
    @ViewChild('inputValue') input: ElementRef;
    selectedPlantName: string;
    selectedNumber: number;
    loader: boolean;
    dayWisePG: boolean = true;
    monthWisePG: boolean = false;
    yearWisePG: boolean = false;
    lifetTimeWisePG: boolean = false;
    lineChartcollectTime: any[] = [];
    emiPraghcollectTime: any[] = [];
    emiPraghRadiant: any[] = [];
    lineChartcollectcollect: any[] = [];
    currentIndex: number = 0;
    predictedPaybackClicked:boolean = false

    alarmSts: AlarmStatusInterface = new AlarmStatusInterface();

    lineChartcollectConsumption: any[] = [];

    totalhistory: any[] = [];
    monthCartListInfo: any[] = [];
    monthCartListInfoPower: any[] = [];
    monthCartListInfoPowerConsumed: any = [];
    originalCost:number

    showhistory: boolean = false;
    gethistory: HistoryDetail;
    getPowerList: PlantList;
    PlantPowerDetails: PlantPower;
    heatMapList: HeatMapIntterface;
    Highcharts: typeof Highcharts = Highcharts;
    blockinfo: any[] = [];
    ppf: boolean = true;
    invert: boolean = false;
    pv: boolean = false;
    roi: boolean = false;
    eme: boolean = false;
    model: NgbDateStruct;
    date: { year: number; month: number };
    activeTab: boolean;
    inverterDetail: InverterDetail[];
    pvList: Pv[] = [];
    voltage = new Voltage();
    ShowPowerGuage: boolean = false;
    selectedDate = '';
    selectedYear = '';
    selectedMonth = '';
    selectedDay = '';
    yearOptions: any[] = [];
    roiChart: any
    predictionParameterForm: FormGroup;
    actualCategories = []
    allCategories = []

    legends: any = [
        { name: 'Power Generated', color: '#d7df27' },
        { name: 'Power Consumed', color: '#EF4323' }
    ];

    //loading:boolean =false;


    EmiChartGraph: Highcharts.Options = {
        title: {
            text: 'EMI Graph',

            style: {
                color: 'black',
                align: 'left'
            }
        },

        xAxis: {
            categories: this.emiPraghcollectTime,
            title: {
                text: '<b >Time</b>'
            }
        },
        yAxis: {
            min: null,
            title: {
                text: '<b >Irradiance</b>',
                align: 'middle'
            },
            labels: {
                overflow: 'justify'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },

        series: [
            {
                data: this.emiPraghRadiant,
                type: 'area'
            }
            // {
            //     data: this.lineChartcollectcollect,
            //     type: 'area'
            // }
        ]
    };

    blockOption: Highcharts.Options = {
        title: {
            text: 'Block Wise Data',

            style: {
                color: 'black',
                align: 'left'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },

        series: [
            {
                data: this.lineChartcollectTime,
                type: 'area'
            },
            {
                data: this.lineChartcollectcollect,
                type: 'area'
            }
        ]
    };

    barChart: Highcharts.Options = {
        title: {
            text: ' ',

            style: {
                color: 'black',
                align: 'left'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },

        series: [
            {
                data: this.monthCartListInfo,
                type: 'column'
            },
            {
                data: this.monthCartListInfoPower,
                type: 'column'
            }
        ]
    };


    powerGauge: any[] = [];

    labelFormatting(dataPoint) {
        return `${dataPoint.data.name}: ${dataPoint.data.value}%`;
    }

    onOpenCalendar(container) {
        container.monthSelectHandler = (event: any): void => {
            container._store.dispatch(container._actions.select(event.date));
        };
        container.setViewMode('year');
    }

    onMonthOpenCalendar(container) {
        container.monthSelectHandler = (event: any): void => {
            container._store.dispatch(container._actions.select(event.date));
        };
        container.setViewMode('month');
    }
    onDayOpenCalendar(container) {
        container.monthSelectHandler = (event: any): void => {
            container._store.dispatch(container._actions.select(event.date));
        };
        container.setViewMode('day');
    }



    pieChartLabel(series: any[], name: string): string {
        const item = series.filter(data => data.name === name);
        if (item.length > 0) {
            return item[0].label;
        }
        return name;
    }
    status: boolean = false;
    dt;
    dtEnv;
    dtEmiEnv;
    dymont: string = '3';
    evnrBanifits: EnvBanifits;
    plantDetail: PlantDetail;
    countAlert: Alert;
    weather = new TodayWeather();
    getStationCode: number = 0;
    showplantGraph: boolean = false;
    getDayRang: number = 3;
    gaugeType = 'semi';
    gaugeValue = 28.3;
    gaugeLabel = 'Total Power';
    installCapacity = 0;
    thick = 11;
    size = 190;
    color = '#3E4A56';
    hight = 160;
    colorgreen: 'green';
    gaugeValueExp = 28.3;
    graphicalView: boolean = false;
    vardata: boolean = true;


    // pvGraphRes : any[] = [ { name: "1", value: 'Volvo', status: 1  },
    //                             { name: "2", value: 'Saab', status: 0 },
    //                             { name: "3", value: 'Opel', status: 1  },
    //                             { name: "4", value: 'Audi', status: 0  }];

    pvGraphRes: any[];
    pvGraphKey: any[];
    isCheckboxDisabled = true;
    selectedCars = [1];
    cars = [
        { id: 1, name: 'Volvo' },
        { id: 2, name: 'Saab', disabled: true },
        { id: 3, name: 'Opel' },
        { id: 4, name: 'Audi' }
    ];
    pvG = [
        { id: 1, name: 'Voltage' },
        { id: 2, name: 'Current' },
        { id: 3, name: 'Power' },

    ];
    inverterGV = [
        { id: 1, name: 'Input DC Graph' },
        { id: 2, name: 'Output Power' },
        { id: 3, name: 'Normalize Power' },

    ];
    @Output() valueChanged = new EventEmitter<string>();
    slides = [
        { img: "https://i.picsum.photos/id/419/200/200.jpg?hmac=yUYGIG3hJhzafcgOl8Drs4iTsia3HynizHXh8nTcvEQ" },
        { img: "https://i.picsum.photos/id/419/200/200.jpg?hmac=yUYGIG3hJhzafcgOl8Drs4iTsia3HynizHXh8nTcvEQ" },
        { img: "https://i.picsum.photos/id/419/200/200.jpg?hmac=yUYGIG3hJhzafcgOl8Drs4iTsia3HynizHXh8nTcvEQ" },
        { img: "https://i.picsum.photos/id/419/200/200.jpg?hmac=yUYGIG3hJhzafcgOl8Drs4iTsia3HynizHXh8nTcvEQ" },
        { img: "https://i.picsum.photos/id/419/200/200.jpg?hmac=yUYGIG3hJhzafcgOl8Drs4iTsia3HynizHXh8nTcvEQ" },
        { img: "https://i.picsum.photos/id/419/200/200.jpg?hmac=yUYGIG3hJhzafcgOl8Drs4iTsia3HynizHXh8nTcvEQ" },

    ];
    slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };
    slideConfigpv = { "slidesToShow": 1, "slidesToScroll": 1 };

    // @ViewChild(SlickCarouselComponent) slickCarousel: SlickCarouselComponent;
    @ViewChild('slickCarousel', { static: true }) slickCarousel: SlickCarouselComponent;

    ///////////////////////////////// Plant detail Graphical View ///////////////////////////////////////////////////////////


    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    ChartBoundary: number = 5;

    public lineChartColors: Array<any> = [
        { // Series A
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'rgba(251, 140, 4, 1)',
        },
        { // Series B
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'rgba(250, 98, 100, 1)',
        },
        { // Series C
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'rgba(125, 125, 0, 1)',
        },
        { // Series D
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'rgba(120, 120, 150, 1)',
        },
        { // Series E
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'rgba(0, 10, 100, 100)',
        }
        // Add more color configurations for additional series
    ];


    graphViewData: Array<any> = [];
    public lineChartData: Array<any> = [
        {
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 35, 55, 45, 67, 39, 81, 72, 80, 81, 56, 55, 40],

            // data: [],
            // label: '',
            backgroundColor: this.lineChartColors[0].backgroundColor,
            borderColor: this.lineChartColors[0].borderColor,
        },
        {
            data: [28, 48, 40, 35, 55, 45, 67, 39, 81, 72, 19, 86, 27, 90, 28, 48, 40, 19, 86, 27, 90],
            // data: [],
            // label: '',        
            backgroundColor: this.lineChartColors[1].backgroundColor,
            borderColor: this.lineChartColors[1].borderColor,
        },
        {
            data: [18, 48, 77, 9, 100, 27, 40, 18, 48, 77, , 35, 55, 45, 67, 39, 81, 29, 100, 27, 40],
            // data: [],
            // label: '',        
            backgroundColor: this.lineChartColors[2].backgroundColor,
            borderColor: this.lineChartColors[2].borderColor,
        },
        {
            data: [55, 80, 45, 75, 27, 68, 34, 55, 80, 35, 55, 45, 67, 39, 81, 72, 45, 75, 27, 68, 34],
            // data: [],
            // label: '',
            backgroundColor: this.lineChartColors[3].backgroundColor,
            borderColor: this.lineChartColors[3].borderColor,
        },
        {
            data: [35, 55, 45, 67, 35, 55, 45, 67, 39, 81, 72, 39, 81, 72, 35, 55, 45, 67, 39, 81, 72],
            // data: [],
            // label: '' ,        
            backgroundColor: this.lineChartColors[4].backgroundColor,
            borderColor: this.lineChartColors[4].borderColor,
        },
    ];

    public lineChartLabels: Array<any> = [];

    public lineChartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'second', // Change this to 'minute', 'hour', 'day', etc. as needed
                        unitStepSize: 5,
                        displayFormats: {
                            second: 'HH:mm:ss', // Format for the Y-axis labels
                        },
                    },
                },
            ],
        },

        interaction: {
            mode: 'index',
            intersect: false,
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            position: 'nearest', // Set the position to 'nearest'
            callbacks: {
                label: function (tooltipItem, data) {
                    var datasetLabel =
                        data.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ': ' + tooltipItem.yLabel;
                },
            },
        },
    };

    public lineChartLegend = true;
    public chartType: 'line' | 'bar' | 'doughnut' = 'line'; // Use a union type for valid chart types




    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////// Heat Map ////////////////////////////////////////////////////////////////
    mapConnected = 0;
    mapDisconnected = 0;
    mapFaulty = 0;
    mapOffline = 0;

    series: any;
    
       formatTimestamp = (timestamp: number | string): string => {
        const date = new Date(timestamp);
        if(this.yearlyRoi === true) {
            return date.toLocaleString('default', {year: 'numeric' });
        }
        return date.toLocaleString('default', { month: 'short', year: 'numeric' });
      };

      // Function to get the range of years from the timestamps
      getYearRange = (timestamps: number[] | string[]): string => {
        const years = timestamps.map(timestamp => new Date(timestamp).getFullYear());
        const minYear = Math.min(...years);
        const maxYear = Math.max(...years);
        if(minYear===maxYear){
            return `(${maxYear})`
        }
        return `(${minYear} - ${maxYear})`;
    };
        monthlyRoi:boolean = false
        yearlyRoi:boolean = true
        installationCost:string
        categories = [];
        seriesData = [];
        yearRangeTitle = '';
        breakEvenYear = '';
        responseMessage = ''
        convertRoiAmount(valueInPKR) {
            if (valueInPKR >= 1000000000) {
                const valueInBillions = valueInPKR / 1000000000;
                return `${valueInBillions.toFixed(2)}B`;
            } else if (valueInPKR >= 1000000) {
                const valueInMillions = valueInPKR / 1000000;
                return `${valueInMillions.toFixed(2)}M`;
            } else if (valueInPKR >= 1000) {
                const valueInThousands = valueInPKR / 1000;
                return `${valueInThousands.toFixed(2)}K`;
            } else {
                return valueInPKR.toFixed(2);
            }
        }
        predictedData1 = []
        predictedRoiData = []
        roiParameters = {}

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    constructor(private _utilityService: UtilityService,private predictionParamService: PredictedRoiService, private datePipe: DatePipe, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private modalService: NgbModal, private toaster:ToastrService) {
        Object.assign(this, {});
        this.dt = new Date().toISOString().slice(0, 10);
        this.dtEnv = new Date().toISOString().slice(0, 10);
        this.dtEmiEnv = new Date().toISOString().slice(0, 10);
    }
    RoiChartinit() {
            this.roiChart = {
                chart: {
                    type: 'line'
                },
                title: {
                    text: null
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: this.categories,
                    title: {
                        text: this.yearRangeTitle,
                        y: 10,
                    },
                    lineWidth: 1,
                    lineColor: '#000',
                    tickColor: '#000',
                    tickWidth: 1,
                    style: {
                        fontFamily: 'Poppins',
                        margin: '20px' // Adjust the margin value to add padding
                    },
                },
                yAxis: {
                    title: {
                        text: '(Amount)',
                        x: -10,
                    },
                    lineWidth: 1,
                    lineColor: '#000',
                    tickColor: '#000',
                    tickWidth: 1,
                    gridLineWidth: 1,
                    gridLineDashStyle: 'longdash',
                    plotLines: [{
                        color: '#FF0000', // Plot line color
                        width: 1,
                        value: 0,
                        zIndex: 2
                    }],
                    labels: {
                        formatter: function () {
                            return this.value.toLocaleString('en-US');
                        }
                    }
                },
                legend: {
                    align: 'left',
                    verticalAlign: 'top',
                    enabled: true,
                    symbolWidth: 50,
                    symbolHeight: 10,
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: function (event) {
                                if (event.target.name === 'Predicted Payback') {
                                    this.predictedPaybackClicked = true;
                                    this.reassignCategories(this.actualCategories, this.allCategories);
                                }
                            }
                        }
                    }
                },
                series: [{
                    name: 'Payback',
                    showInLegend: true,
                    data: this.seriesData,
                    color: '#FFBF00',  // Color for the line
                    marker: {
                        fillColor: '#EF4323',  // Color for the points
                        lineWidth: 2,
                        lineColor: '#FFBF00'  // Outline color for the points, usually same as line color
                    }
                },
                {
                    name: 'Predicted Payback',
                    data: this.yearlyRoi ? this.predictedData1 : [],
                    showInLegend: true,
                    dashStyle: 'Dash',  
                    color: '#9cf11a',  
                    marker: {
                        fillColor: '#9cf11a',  
                        lineWidth: 2,
                        lineColor: '#a5fc6c',
                        symbol:'circle'
                    }
                }
            ]
            };
        // }
    }

    
    reassignCategories(actualCategories,allCategories) {
        if (this.predictedPaybackClicked) {
            this.categories = actualCategories; 
        } else {
            this.categories = allCategories; 
        }
    }
    

    ngOnInit(): void {
        this.loader = true;
        if (!this.selectedYear) {
            const currentDate = new Date();
            this.selectedYear = this.datePipe.transform(currentDate, 'yyyy');
            const formattedDate = this.datePipe.transform(new Date(this.selectedYear), 'yyyy-MM-dd');
            this.selectedDate = formattedDate;
        }
        if (!this.selectedMonth) {
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            this.selectedMonth = `${month}/${year}`;
            const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
            this.selectedDate = formattedDate;
        }
        if (!this.selectedDay) {
            const currentDate = new Date();
            this.selectedDay = this.datePipe.transform(currentDate, 'dd/MM/yyyy');
            const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
            this.selectedDate = formattedDate;
        }
        this._utilityService.selectedProduct$.subscribe((value) => {
            this.getStationCode = value;
        });
        this._utilityService.selectedPlantName$.subscribe((plantName: string) => {
            this.selectedPlantName = plantName;
        });
        this.getWeatherUpdates();
        this.getPlantDetailList();
        this.envirmentBanifit();
        this.getpowerFlow();
        this.showAlert();
        // this.monthChartInfo();
        this.selectInverterDetail();
        this.pvDataList();
        this.getAlarmStatus(this.getStationCode);
        this.geHeatMapData();

        this.loader = false;

        this.fiveMinData();
        this.EmiGraph(this.dtEnv);

        this.predictionParameterForm = this.formBuilder.group({
            cost: [null, [Validators.required, Validators.minLength(3),Validators.maxLength(11)]],
            // date: [null, [Validators.required]],
            baseProduction: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(11)]],
            nextYearProduction: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(11)]],
            tariffRate: [null, [Validators.required, Validators.pattern(/^100$|^\d{1,2}(\.\d{1,2})?$/)]],
            dipPercentage: [null, [Validators.required, Validators.pattern(/^100$|^\d{1,2}(\.\d{1,2})?$/)]]
        }) 

    }

    ngAfterViewInit(): void {
    }

    getMonthlyRoiGraph(){
        this.loader = true;
        this._utilityService.monthlyRoiGraph(this.getStationCode).subscribe(res=>{
            if(res && res.status === 200){
                this.loader = false;
                this.monthlyRoi = true;
                this.yearlyRoi = false;
                this.installationCost = this.convertToThousandsMillions(res.installation_cost)
                this.seriesData = res.data.map(item => item['payback']);
                this.categories = res.data.map(item => this.formatTimestamp(item['collectTime']));
                this.yearRangeTitle = this.getYearRange(res.data.map(item => item['collectTime']));
                if (!this.seriesData || this.seriesData.length === 0){
                    this.responseMessage = "Please add Installation Cost and Ensure Plant Association with Tariff Type and Tariff Company."
                }
                this.RoiChartinit()

            }
            else {
                this.loader = false;
                this.seriesData = []
                this.categories = []
                this.yearRangeTitle = ''
                this.yearlyRoi = false
                if (!this.seriesData || this.seriesData.length === 0){
                    this.responseMessage = "Please add Installation Cost and Ensure Plant Association with Tariff Type and Tariff Company."
                }
            }
        })
        , error => {
            this.loader = false;
            this.responseMessage = error.error.message;
            console.error('Error fetching ROI graph data:', error);
        }
    }

    findBreakEvenYear(predictedData: string | any[], installationCost: any | number) {
        let closestDiff = Infinity;
        let breakEvenYear = null;
    
        for (let i = 0; i < predictedData.length; i++) {
            const diff = Math.abs(predictedData[i].predicted_payback - parseInt(installationCost));
            if (diff < closestDiff) {
                closestDiff = diff;
                breakEvenYear = this.categories[i];
            }
        }
    
        return breakEvenYear;
    }

    getYearlyRoiGraph(){
        this.loader = true;
        this._utilityService.getYearlyRoiGraph(this.getStationCode).subscribe(res=>{
            if(res && res.status === 200){
                this.loader = false;
                this.monthlyRoi = false;
                this.yearlyRoi = true;

                const actualDataMap = new Map(res.data['yearly_roi'].map(item=> [this.formatTimestamp(item['collectTime']), item['payback']]));
                this.installationCost = this.convertToThousandsMillions(res.installation_cost)
                this.actualCategories = [...actualDataMap.keys()];
                const predictedDataMap = new Map(res.data['predicted_roi'].map(item => [this.formatTimestamp(item['collectTime']), item['predicted_payback']]));
                const predictedCategories = [...predictedDataMap.keys()];

                this.roiParameters = res.parameters

                this.allCategories = [...new Set([...this.actualCategories, ...predictedCategories])].sort();
                this.categories = this.allCategories;

                this.seriesData = this.categories.map(category => actualDataMap.get(category) || null);
                this.predictedData1 = this.categories.map(category => predictedDataMap.get(category) || null);

                // this.yearRangeTitle = this.getYearRange(res.data['yearly_roi'].map(item => item['collectTime']));
                this.yearRangeTitle = this.getYearRange(this.allCategories);
                this.breakEvenYear = this.findBreakEvenYear(res.data.predicted_roi, this.installationCost);


                if (!this.seriesData || this.seriesData.length === 0){
                    this.responseMessage = "Please add Installation Cost and Ensure Plant Association with Tariff Type and Tariff Company."
                }
                this.RoiChartinit()
            }
            else {
                this.loader = false;
                this.seriesData = []
                this.monthlyRoi = false
                this.categories = []
                this.yearRangeTitle = ''
                if (!this.seriesData || this.seriesData.length === 0){
                    this.responseMessage = "Please add Installation Cost and Ensure Plant Association with Tariff Type and Tariff Company."
                }            
            }
        })
    }
    getAlarmStatus(StationCode: any) {
        this._utilityService.GetPlantAlarmCount(StationCode).subscribe((res) => {
            for (var item of res.data.alarm_status) {
                if (item.alarm_type == 'Critical') {
                    this.alarmSts.Critical = item.count;
                }
                else if (item.alarm_type == 'Major') {
                    this.alarmSts.Major = item.count;
                }
                else if (item.alarm_type == 'Minor') {
                    this.alarmSts.Minor = item.count;
                }
                else if (item.alarm_type == 'Warning') {
                    this.alarmSts.Warning = item.count;
                }
            };

            this.alarmSts.TotalAlarms = this.alarmSts.Critical + this.alarmSts.Major + this.alarmSts.Minor + this.alarmSts.Warning;

            this.series = [
                {
                    "name": "Critical",
                    "value": (this.alarmSts.Critical / this.alarmSts.TotalAlarms * 100).toFixed(1),
                    "label": Math.round((this.alarmSts.Critical / this.alarmSts.TotalAlarms * 100)).toString() + "%"
                },
                {
                    "name": "Major",
                    "value": (this.alarmSts.Major / this.alarmSts.TotalAlarms * 100).toFixed(1),
                    "label": Math.round((this.alarmSts.Major / this.alarmSts.TotalAlarms * 100)).toString() + "%"
                },
                {
                    "name": "Minor",
                    "value": (this.alarmSts.Minor / this.alarmSts.TotalAlarms * 100).toFixed(1),
                    "label": Math.round((this.alarmSts.Minor / this.alarmSts.TotalAlarms * 100)).toString() + "%"
                },
                {
                    "name": "Warning",
                    "value": (this.alarmSts.Warning / this.alarmSts.TotalAlarms * 100).toFixed(1),
                    "label": Math.round((this.alarmSts.Warning / this.alarmSts.TotalAlarms * 100)).toString() + "%"
                }
            ];
        });
    }

    showPlantList() {
        this._utilityService.setUrl(false);
    }

    sendDeviD(value: string) {
        this.valueChanged.emit(value);
    }

    getBackgroundColor(colorValue: string): string {
        // Map color values to corresponding colors
        if (colorValue === 'Red') {
            return '#EF4323'; // Red color
        } else if (colorValue === 'Green') {
            return '#AFF205'; // Green color
        } else if (colorValue === 'Faulty') {
            return '#EF4323'; //  color
        }
        else {
            return '';
        }
    }


    ngDoCheck() {
    }
    onSelect(data): void {
    }
    onActivate(data): void {
    }
    onDeactivate(data): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    getWeatherUpdates() {
        this._utilityService
            .setWeatherUpdates(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    this.weather = res.data;
                }
            });
    }

    geHeatMapData() {
        this._utilityService
            .getHeatMapList(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    //debugger;
                    this.heatMapList = res;
                }
            });
    }



    isCheckBoxDisabled(item): boolean {
        if (item.status == false) {
            const filteredObjects = this.pvGraphKey.filter(obj => obj.status === 1 || obj.status === true);
            if (filteredObjects.length < 5) {
                return false;
            }
            return true;
        }
        else
            return false;
    }

    SetGraphData(): void {
        //debugger;

        // Replace 5 with your desired constant value

        for (let index = 0; index < this.ChartBoundary; index++) {
            //this.lineChartData[index].data.length = 0;
            this.lineChartData[index].label = "";
        }

        const filteredObjects = this.pvGraphKey.filter(obj => obj.status === 1 || obj.status === true);

        let index = 0;

        filteredObjects.forEach((e) => {
            if (index < this.ChartBoundary) {
                this.lineChartData[index].data = this.pvGraphRes[0][e.name];
                this.lineChartData[index].label = e.value;
                index = index + 1;
            }
        });

        this.graphViewData.length = 0;

        //debugger;
        this.lineChartData.forEach((obj) => {
            if (!(obj.label === undefined || obj.label === null || obj.label === "")) {
                if (obj.data.length > 0)
                    this.graphViewData.push(obj);
            }
        });

        this.chart.update();
        this.cdr.detectChanges();
    }

    getPlantDetailList() {
        this.powerGauge = [];
        this.ShowPowerGuage = false;
        this._utilityService
            .setPlantDetailList(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    //debugger;
                    this.plantDetail = res.data[0];
                    this.plantDetail.costFormatted = this.convertToThousandsMillions(this.plantDetail.cost)
                    this.powerGauge = [
                        {
                            name: 'Power',
                            value: this.plantDetail.powerNow,
                        },
                    ];
                }

                setTimeout(() => {
                    this.ShowPowerGuage = true;
                    this.getYearlyRoiGraph();
                }, 1000);
            });
    }

    convertToThousandsMillions(valueInPKR, limitThousands = 10000, limitMillions = 1000000) {
        if (valueInPKR >= limitMillions) {
            const valueInMillions = valueInPKR / 1000000;
            return `${valueInMillions.toFixed(2)} million PKR`;
        } else if (valueInPKR >= limitThousands) {
            const valueInThousands = valueInPKR / 1000;
            return `${valueInThousands.toFixed(2)}k PKR`;
        } else {
            return `${valueInPKR.toFixed(2)} PKR`;
        }
    }

    onCheckboxChange(): void {
        ////debugger;   
        // Handle checkbox change event        
        this.SetGraphData();
        // You can perform additional actions based on the checkbox state here
    }

    showAlert() {
        this._utilityService
            .countAlertlist(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    this.countAlert = res.data;
                }
            });
    }

    sendDate(e: any) {
        this.getDayRang = e.target.value;
        this.dymont = e.target.value;
        this.envirmentBanifit();
        this.status = this.status;
    }

    EmiGraph(e) {
        //debugger;
        this.loader = true;

        this.dtEnv = e;
        this.emiPraghcollectTime = [];
        this.emiPraghRadiant = [];
        this.blockinfo = [];
        this._utilityService
            .setEmiGraphWithDate(this.getStationCode, this.dtEnv)
            .subscribe((res) => {
                // debugger;
                this.totalhistory = res.data;

                if (this.totalhistory.length > 0) {
                    this.loader = false;
                    var blocklist = [];
                    var Consumptionlist = [];
                    blocklist = res.data;
                    // Consumptionlist = res.data.consumptionData;
                    console.log("🚀 ~ file: plant-detail.component.ts:423 ~ PlantDetailComponent ~ .subscribe ~ blocklist:", blocklist)
                    blocklist.forEach((e) => {
                        ////debugger                  

                        const date = new Date(Number(e.collectTime) * 1000);
                        const time = this.datePipe.transform(date, 'hh:mm a');
                        this.emiPraghcollectTime.push(
                            time
                        );

                        this.emiPraghRadiant.push(+(e.radiant_line));
                    });

                } else {
                    this.showhistory = true;
                }
                this.EmiChartGraph = {
                    title: {
                        text: '',

                        style: {
                            color: 'black',
                            align: 'left'
                        }
                    },

                    xAxis: {
                        categories: this.emiPraghcollectTime,
                        title: {
                            text: '<b >Time</b>'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '<b >Irradiance</b>',
                            align: 'middle'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },

                    series: [
                        {
                            name: 'Irradiance Values',
                            data: this.emiPraghRadiant,
                            //data: [31, 40, 28, 51, 42, 109, 100],
                            type: 'area',
                            color: '#AA4A56'
                        }
                        // ,
                        // {
                        //     name: 'Power Generated',
                        //     data: this.emiPraghRadiant,
                        //     type: 'area',
                        //     color: '#3E4A56'
                        // }
                    ]
                };
            });

        this.loader = false;
    }


    envirmentBanifit() {
        this._utilityService
            .getEnvBnifits(this.getStationCode, this.dt, this.getDayRang)
            .subscribe((res) => {
                if (res) {
                    //debugger;                    
                    this.evnrBanifits = res.data[0];
                }
            });
    }
    dateClear(e) {
        this.loader = true;
        this.envirmentBanifit();
        this.dt = e;
        this.loader = false;
    }
    dayWiseCheck(selectedMonth: string) {
        const selectedDate = new Date(selectedMonth);

        // Format the date using DatePipe
        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.loader = true;
        this.selectedDate = formattedDate;
        this.dateClearEnv(formattedDate);
        this.loader = false;
    }

    // monthWIseCheck(e) {
    //     this.loader = true;
    //     this.dt = e;
    //     this.monthChartInfo();
    //     this.loader = false;
    // }
    monthWiseCheck(selectedMonth: string) {
        const selectedDate = new Date(selectedMonth);

        // Format the date using DatePipe
        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.loader = true;
        this.selectedDate = formattedDate;
        this.monthChartInfo();
        this.loader = false;
    }

    // yearWIseCheck(e) {
    //     this.loader = true;
    //     this.dt = e;
    //     this.yearChartInfo();
    //     this.loader = false;
    // }
    yearWiseCheck(selectedYear: string) {
        // Create a new date with the selected year and January 1 as the month and day
        const selectedDate = new Date(selectedYear);

        // Format the date using DatePipe
        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.loader = true;
        this.selectedDate = formattedDate;
        this.yearChartInfo();
        this.loader = false;
    }
    clickEvent() {
        this.status = !this.status;
    }

    dateClearEnv(e) {
        this.loader = true;
        this.dtEnv = e;
        this.lineChartcollectTime = [];
        this.lineChartcollectcollect = [];
        this.lineChartcollectConsumption = [];
        this.blockinfo = [];
        this._utilityService
            .setfiveMinDataWithDate(this.getStationCode, this.selectedDate)  // this.dtEnv replaced by this.selectedDate
            .subscribe((res) => {
                //debugger;
                this.dayWisePG = true;
                this.monthWisePG = false;
                this.yearWisePG = false;
                // this.lifetTimeWisePG = false;
                this.totalhistory = res.data.data;

                if (this.totalhistory.length > 0) {
                    this.loader = false;
                    var blocklist = [];
                    blocklist = res.data.data;
                    // Consumptionlist = res.data.consumptionData;
                    console.log("🚀 ~ file: plant-detail.component.ts:423 ~ PlantDetailComponent ~ .subscribe ~ blocklist:", blocklist)
                    blocklist.forEach((e) => {
                        const date = new Date(Number(e.timestamp) * 1000);
                        const time = this.datePipe.transform(date, 'hh:mm a');
                        this.lineChartcollectTime.push(
                            time
                        );
                        // New Check added if generation value is 0, if so, replace it with '-'
                        const generation = e.generation === 0 ? '-' : e.generation;
                        const consumption = e.consumption === 0 ? '-' : e.consumption;

                        // this.lineChartcollectcollect.push(e.generation);
                        this.lineChartcollectcollect.push(generation);
                        console.log('lineChartcollectcollect', this.lineChartcollectcollect)
                        // this.lineChartcollectConsumption.push(e.consumption);
                        this.lineChartcollectConsumption.push(consumption);
                    });
                } else {
                    this.showhistory = true;
                }

                this.blockOption = {
                    title: {
                        text: '',

                        style: {
                            color: 'black',
                            align: 'left'
                        }
                    },

                    xAxis: {
                        categories: this.lineChartcollectTime,
                        title: {
                            text: '<b >Time</b>'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '<b >Power</b>',
                            align: 'middle'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        },
                        area: {
                            // fillColor: {
                            //     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                            //     stops: [
                            //         [0, 'rgba(170, 74, 86, 0)'], // Transparent color for 'Power Consumed'
                            //         [1, 'rgba(62, 74, 86, 0)'], // Transparent color for 'Power Generated'
                            //     ]
                            // },
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },

                    series: [
                        {
                            name: 'Power Consumed',
                            data: this.lineChartcollectConsumption,
                            //data: [31, 40, 28, 51, 42, 109, 100],
                            type: 'area',
                            color: '#EF4323'
                        },
                        {
                            name: 'Power Generated',
                            data: this.lineChartcollectcollect,
                            type: 'area',
                            color: '#AFF205'
                        }
                    ]
                };
                this.loader = false;

            });

    }

    fiveMinData() {
        this.loader = true;
        this.lineChartcollectTime = [];
        this.lineChartcollectcollect = [];
        this.lineChartcollectConsumption = [];
        this.blockinfo = [];
        this._utilityService
            .setfiveMinData(this.getStationCode)
            .subscribe((res) => {
                ////debugger;
                this.dayWisePG = true;
                this.monthWisePG = false;
                this.yearWisePG = false;
                this.lifetTimeWisePG = false
                this.totalhistory = res.data.data;

                if (this.totalhistory.length > 0) {
                    this.loader = false;
                    var blocklist = [];
                    blocklist = res.data.data;
                    // Consumptionlist = res.data.consumptionData;
                    console.log("🚀 ~ file: plant-detail.component.ts:423 ~ PlantDetailComponent ~ .subscribe ~ blocklist:", blocklist)
                    blocklist.forEach((e) => {
                        ////debugger                  
                        const date = new Date(Number(e.timestamp) * 1000);
                        const time = this.datePipe.transform(date, 'hh:mm a');
                        this.lineChartcollectTime.push(
                            time
                        );
                        // New Check added if generation value is 0, if so, replace it with '-'
                        const generation = e.generation === 0 ? '-' : e.generation;
                        const consumption = e.consumption === 0 ? '-' : e.consumption;

                        // this.lineChartcollectcollect.push(e.generation);
                        this.lineChartcollectcollect.push(generation);
                        // this.lineChartcollectConsumption.push(e.consumption);
                        this.lineChartcollectConsumption.push(consumption);
                    });
                } else {
                    this.showhistory = true;
                }
                this.blockOption = {
                    title: {
                        text: '',

                        style: {
                            color: 'black',
                            align: 'left'
                        }
                    },

                    xAxis: {
                        categories: this.lineChartcollectTime,
                        title: {
                            text: '<b >Time</b>'
                        }
                    },
                    yAxis: {
                        min: null,
                        title: {
                            text: '<b >Power</b>',
                            align: 'middle'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        },
                        area: {
                            // fillColor: {
                            //     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                            //     stops: [
                            //         [0, 'rgba(170, 74, 86, 0)'], // Transparent color for 'Power Consumed'
                            //         [1, 'rgba(62, 74, 86, 0)'], // Transparent color for 'Power Generated'
                            //     ]
                            // },
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },

                    series: [
                        {
                            name: 'Power Consumed',
                            data: this.lineChartcollectConsumption,
                            //data: [31, 40, 28, 51, 42, 109, 100],
                            type: 'area',
                            color: '#EF4323'
                        },
                        {
                            name: 'Power Generated',
                            data: this.lineChartcollectcollect,
                            type: 'area',
                            color: '#AFF205'
                        }
                    ]
                };
            });

        this.loader = false;
    }

    getpowerFlow() {
        this.showplantGraph = false;
        this._utilityService
            .getpowerFlowList(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    this.PlantPowerDetails = res.data[0];
                    this.showplantGraph = true;
                }
            });
    }

    roundNullToZero(value: any): number {

        if (isNaN(value) || value == undefined || value == null) {
            return 0;
        }

        if (value == 0.0)
            return 0;

        if (value == -0.0)
            return 0;

        return value;
    }

    roundOffOneDec(value: any) {

        if (isNaN(value) || value == undefined || value == null) {
            return 0;
        }
        return (parseFloat(value)).toFixed(1);
    }

    getHeatMap() {
        this.showplantGraph = false;
        this._utilityService
            .getHeatMapList(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    this.PlantPowerDetails = res.data[0];
                    this.showplantGraph = true;
                }
            });
    }

    monthChartInfo() {
        this.loader = true;
        this.monthCartListInfo = [];
        this.monthCartListInfoPower = [];
        this.monthCartListInfoPowerConsumed = [];
        this._utilityService
            .getMonthChart(this.getStationCode, this.selectedDate)  // this.dt replaced by this.selectedDate
            .subscribe((res) => {
                if (res) {
                    this.monthWisePG = true;
                    this.yearWisePG = false;
                    this.dayWisePG = false;
                    this.lifetTimeWisePG = false
                    var monthCartList = [];
                    monthCartList = res.data;
                    ////debugger;
                    monthCartList.forEach((e) => {
                        const newdate = new Date(Number(e.timestamp) * 1000);

                        const datetime = this.datePipe.transform(newdate, 'yyyy-MM-dd'); //'dd/MM/yyyy'

                        this.monthCartListInfo.push(
                            //e.collectTime.toString().split('T')[0]
                            datetime
                        );
                        this.monthCartListInfoPower.push(e.powerGenerated);
                        this.monthCartListInfoPowerConsumed.push(e.powerConsumed);
                        // if(e.powerConsumed == null)
                        // {
                        //     this.monthCartListInfoPowerConsumed.push(e.powerGenerated);
                        // }
                        // else
                        // {
                        //     this.monthCartListInfoPowerConsumed.push(e.powerConsumed);
                        // }
                        // this.monthCartListInfo.push(
                        //     e.collectTime.toString().split('T')[0]
                        // );
                        // this.monthCartListInfoPower.push(e.power);

                    });


                    ////debugger;
                }

                this.barChart = {
                    title: {
                        text: '',

                        style: {
                            color: 'green',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.monthCartListInfo,
                        title: {
                            text: '<b >Date</b>'
                        }
                    },
                    yAxis: {
                        min: null,
                        title: {
                            text: '<b >Power</b>',
                            align: 'middle'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },

                    series: [
                        // {
                        //     name: 'Power',
                        //     data: this.monthCartListInfo,
                        //     color: '#3E4A56',
                        //     type: 'column'
                        // },
                        {
                            name: 'Power Consumed',
                            data: this.monthCartListInfoPowerConsumed,
                            color: '#EF4323',
                            type: 'column'
                        },
                        {
                            name: 'Power Genrated',
                            data: this.monthCartListInfoPower,
                            color: '#AFF205',
                            type: 'column'
                        }
                    ]
                };
            });

        this.loader = false;
    }


    yearChartInfo() {
        this.loader = true;
        this.monthCartListInfo = [];
        this.monthCartListInfoPower = [];
        this.monthCartListInfoPowerConsumed = [];
        this._utilityService
            .getyearChart(this.getStationCode, this.selectedDate)
            .subscribe((res) => {
                if (res) {

                    this.monthWisePG = false;
                    this.yearWisePG = true;
                    this.dayWisePG = false;
                    this.lifetTimeWisePG = false
                    var monthCartList = [];
                    monthCartList = res.data;

                    // monthCartList.forEach((e) => {
                    //     this.monthCartListInfo.push(
                    //         e.collectTime.toString().split('T')[0]
                    //     );
                    //     this.monthCartListInfoPower.push(e.power);
                    // });
                    monthCartList.forEach((e) => {
                        const newdate = new Date(Number(e.timestamp) * 1000);
                        const datetime = this.datePipe.transform(newdate, 'yyyy-MM'); //'dd/MM/yyyy'
                        ////debugger;
                        this.monthCartListInfo.push(
                            //e.collectTime.toString().split('T')[0]
                            datetime
                        );
                        //debugger;
                        this.monthCartListInfoPower.push(e.powerGenerated);
                        this.monthCartListInfoPowerConsumed.push(e.powerConsumed);
                        // this.monthCartListInfo.push(
                        //     e.collectTime.toString().split('T')[0]
                        // );
                        // this.monthCartListInfoPower.push(e.power);

                    });
                }

                this.barChart = {
                    title: {
                        text: '',

                        style: {
                            color: 'green',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.monthCartListInfo,
                        title: {
                            text: '<b >Date</b>'
                        }
                    },
                    yAxis: {
                        min: null,
                        title: {
                            text: '<b >Power</b>',
                            align: 'middle'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },

                    series: [
                        {
                            name: 'Power Consumed',
                            data: this.monthCartListInfoPowerConsumed,
                            color: '#EF4323',
                            type: 'column'
                        },
                        {
                            name: 'Power Genrated',
                            data: this.monthCartListInfoPower,
                            color: '#AFF205',
                            type: 'column'
                        }
                    ]
                };
            });

        this.loader = false;

    }

    lifeTimeChartInfo() {
        this.loader = true;
        this.monthCartListInfo = [];
        this.monthCartListInfoPower = [];
        this.monthCartListInfoPowerConsumed = [];
        this._utilityService
            .getliftTimeChart(this.getStationCode)
            .subscribe((res) => {
                if (res) {
                    this.lifetTimeWisePG = true
                    this.monthWisePG = false;
                    this.yearWisePG = false;
                    this.dayWisePG = false;
                    var monthCartList = [];
                    monthCartList = res.data;

                    // monthCartList.forEach((e) => {
                    //     this.monthCartListInfo.push(
                    //         e.collectTime.toString().split('T')[0]
                    //     );
                    //     this.monthCartListInfoPower.push(e.power);
                    // });
                    monthCartList.forEach((e) => {
                        const newdate = new Date(Number(e.timestamp) * 1000);
                        const datetime = this.datePipe.transform(newdate, 'yyyy-MM-dd'); //'dd/MM/yyyy'
                        this.monthCartListInfo.push(
                            //e.collectTime.toString().split('T')[0]
                            datetime
                        );
                        //debugger;
                        this.monthCartListInfoPower.push(e.powerGenerated);
                        this.monthCartListInfoPowerConsumed.push(e.powerConsumed);
                        // this.monthCartListInfo.push(
                        //     e.collectTime.toString().split('T')[0]
                        // );
                        // this.monthCartListInfoPower.push(e.power);

                    });
                }

                this.barChart = {
                    title: {
                        text: '',

                        style: {
                            color: 'green',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.monthCartListInfo,
                        title: {
                            text: '<b >Date</b>'
                        }
                    },
                    yAxis: {
                        min: null,
                        title: {
                            text: '<b >Power</b>',
                            align: 'middle'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },

                    series: [
                        {
                            name: 'Power Consumed',
                            data: this.monthCartListInfoPowerConsumed,
                            color: '#EF4323',
                            type: 'column'
                        },
                        {
                            name: 'Power Genrated',
                            data: this.monthCartListInfoPower,
                            color: '#AFF205',
                            type: 'column'
                        }
                    ]
                };
            });

        this.loader = false;

    }

    showData(strin: string, selectedNumber: number, devId: number) {
        this.loader = true;
        this.selectedNumber = selectedNumber;
        this._utilityService.sendDeviD(this.selectedNumber);
        this._utilityService.sendDeviD2(this.getStationCode);
        if (strin === 'graphicalView') {
            this.vardata = false;
            this.graphicalView = true;
            this.setGraphicalView(devId, this.dtEmiEnv);
        }
        if (strin === 'data') {
            this.graphicalView = false;
            this.vardata = true;
        }
        this.loader = false;
    }


    showDataWithDate(strin: string, selectedNumber: number, devId: number, e) {
        this.loader = true;
        this.selectedNumber = selectedNumber;
        this._utilityService.sendDeviD(this.selectedNumber);
        this._utilityService.sendDeviD2(this.getStationCode);
        if (strin === 'graphicalView') {
            this.vardata = false;
            this.graphicalView = true;

            this.setGraphicalView(devId, e);
        }
        if (strin === 'data') {
            this.graphicalView = false;
            this.vardata = true;
        }

        this.loader = false;
    }


    setGraphicalView(devId: number, dt: Date) {
        this.loader = true;
        this._utilityService.newPvGraphsList(devId, dt).subscribe((res) => {
            if (res) {

                this.pvGraphRes = res;
                this.pvGraphKey = this.pvGraphRes[0].keys;
                this.processGraphTime(this.pvGraphRes[0].time)
                this.SetGraphData();
                this.loader = false;
            }
        })
    }

    processGraphTime(arrTime: any) {
        this.lineChartLabels.length = 0;
        arrTime.forEach((e) => {
            const date = new Date(Number(e) * 1000);
            const time = this.datePipe.transform(date, 'hh:mm a');
            this.lineChartLabels.push(
                time
            );
        });

        //lineChartLabels.push()
    }


    enertyFlow(st: string) {
        if (st === 'energyFlow') {
            this.ppf = true;
            this.invert = false;
            this.pv = false;
            this.roi = false;
            this.eme = false;
        }
        if (st === 'inverter') {
            this.ppf = false;
            this.invert = true;
            this.pv = false;
            this.eme = false;
            this.roi = false;
        }
        if (st === 'pv') {
            this.ppf = false;
            this.invert = false;
            this.pv = true;
            this.eme = false;
            this.roi = false;
            this.EmiGraph(this.dtEnv);
        }
        if (st === 'emi') {
            this.ppf = false;
            this.invert = false;
            this.pv = false;
            this.eme = true;
            this.roi = false;
        }
        if (st === 'roi') {
            this.ppf = false;
            this.invert = false;
            this.pv = false;
            this.eme = false;
            this.roi = true;
        }
    }


    openUpdateCostModal(ticketDetail: any) {
        this.originalCost = this.plantDetail.cost;
        this.modalService.open(ticketDetail, { windowClass: "complainModelClass" });
    }
    openPredictionModal(predictionModal: any) {
        this.originalCost = this.plantDetail.cost;
        this.modalService.open(predictionModal, { windowClass: "complainModelClass" });
        setTimeout(() => {
            this.predictionParameterForm.patchValue({
              cost: this.originalCost ? formatNumber(this.originalCost, 'en-US', '2.0-0') : this.originalCost,
              baseProduction: this.roiParameters['base'],
              nextYearProduction: this.roiParameters['next_year_production'],
              tariffRate : this.roiParameters['tariff_rate'],
              dipPercentage : this.roiParameters['dip_percentage']
            });
          }, 100);
    }
    
    handleCancel(dismiss: Function) {
        this.plantDetail.cost = this.originalCost;
        dismiss('Cancel click');
      }

    updateInstallationCost() {

        this.loader = true;
        this._utilityService.updateInstallationCost(this.plantDetail.stationCode, this.plantDetail.cost).subscribe((res) => {
            if (res && res.status === 200) {
                this.loader = false;
                this.plantDetail.costFormatted = this.convertToThousandsMillions(res.data.cost)
                Swal.fire({
                    icon: 'success',
                    title: 'Cost Updated',
                    text: 'ROI Graph needs to be processed again.',
                    showConfirmButton: false,
                    timer: 1600
                });
                setTimeout(() => {
                    this.getYearlyRoiGraph();
                }, 2000);
            } 
            else {
                this.loader = false;
                this.toaster.error(res.message)
                this.monthlyRoi = true
                this.yearlyRoi = false
                this.plantDetail.costFormatted = this.convertToThousandsMillions(this.plantDetail.cost)
            }
        })
    }

    onPredictionParamters() {
        if(this.predictionParameterForm.valid){
            const payload = {
                stationCode: this.plantDetail.stationCode,
                cost: this.predictionParameterForm.value.cost ? parseFloat(this.predictionParameterForm.value.cost.replace(/,/g, '')) : null,
                // date: this.predictionParameterForm.value.date ? this.datePipe.transform(this.predictionParameterForm.value.date, 'yyyy-MM-dd') : null,
                baseProduction: parseInt(this.predictionParameterForm.value.baseProduction),
                nextYearProduction: parseInt(this.predictionParameterForm.value.nextYearProduction),
                tariffRate: parseFloat(this.predictionParameterForm.value.tariffRate),
                dipPercentage: parseFloat((this.predictionParameterForm.value.dipPercentage / 100).toFixed(3)),
            }
            this.loader = true;
            this._utilityService.predictedRoiApi(payload).subscribe((res) => {
                if (res && res.status === 201) {
                    this.loader = false;
                    this.predictedRoiData = res.data
                    Swal.fire({
                        icon: 'success',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 1600
                    });
                    setTimeout(() => {
                        this.getYearlyRoiGraph();
                    }, 2000);

                }
                else {
                    this.loader = false;
                    this.predictionParameterForm.reset()
                    this.toaster.error(res.message)
                    this.monthlyRoi = true
                    this.yearlyRoi = false
                }
            })
        }
        else {
            this.toaster.error('Please add Valid Form Data')
            this.predictionParameterForm.reset()
        }
    }

    reCalculateRoi() {

        this.loader = true;
        this._utilityService.reCalculateRoi(this.plantDetail.stationCode).subscribe((res) => {
            if (res && res.status === 200) {
                this.loader = false;
                setTimeout(() => {
                    this.getMonthlyRoiGraph();
                }, 1500);
            }
            else {
                this.loader = false;
                this.toaster.error(res.message)
                this.monthlyRoi = true
                this.yearlyRoi = false
            }
        })
    }

    toggleDisabled() {
        const car: any = this.cars[1];
        car.disabled = !car.disabled;
    }
    pvlist(strin: string, selectedNumber: number) {
        this.loader = true;
        this.selectedNumber = selectedNumber;
        this._utilityService.sendDeviD(this.selectedNumber);
        this._utilityService.sendDeviD2(this.getStationCode);
        if (strin === 'graphicalView') {
            this.vardata = false;
            this.graphicalView = true;
        }
        if (strin === 'data') {
            this.graphicalView = false;
            this.vardata = true;
        }
        this.loader = false;
    }
    selectInverterDetail() {
        this._utilityService.inverterPrimeData(this.getStationCode).subscribe((res) => {
            if (res) {
                this.inverterDetail = res.data;

                this.inverterDetail = res.data.map((item, index) => {
                    item.serialNumber = index + 1;

                    return item;
                });
            }
        });
    }
    prev() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        }
    }

    next() {
        if (this.currentIndex < this.inverterDetail.length - 1) {
            this.currentIndex++;
        }
    }
    pvDataList() {
        this._utilityService.pvgetData(this.getStationCode).subscribe((res) => {
            if (res) {
                this.pvList = res.data;
            }
        });
    }
    addSlide() {
        this.slides.push({ img: "http://placehold.it/350x150/777777" })
    }

    removeSlide() {
        this.slides.length = this.slides.length - 1;
    }

    slickInit(e) {
        console.log('slick initialized');
    }

    breakpoint(e) {
    }

    afterChange(e) {
        console.log('after change')
    }
    setEvent(event: number) {
        console.log(event);
    }
    afterChangeValue(e) {
        this._utilityService.sendDeviD(this.selectedNumber);
    }

    beforeChange(e) {
        console.log('beforeChange');
    }
}
