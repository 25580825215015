import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as Rx from 'rxjs';
import { catchError, delay, retryWhen, share, take, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';



@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  constructor(private toasterService:ToastrService) { }
  public socket$: WebSocketSubject<any> | undefined;
  private connectionState$: Rx.BehaviorSubject<boolean> = new Rx.BehaviorSubject<boolean>(false);
  public comments$: Rx.BehaviorSubject<any[]> = new Rx.BehaviorSubject<any[]>([]);

  
  public ticket_id:number
  public user_id: number

  public getConnectionState() {
    return this.connectionState$.asObservable();
  }

  public getComments(): Rx.Observable<any[]> {
    return this.comments$.asObservable();
  }


  public connect() {
    const url = `ws://127.0.0.1:8000/comments/${this.ticket_id}/?user_id=${this.user_id}`; 
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.create(url);
    }

    return this.socket$;
  }

  // private create(url: string): WebSocketSubject<any> {
  //   this.socket$ = webSocket(url);

  //   this.socket$.subscribe(
  //     (message) => {
  //       this.connectionState$.next(true);
  //       console.log('Socket Connected and received messages: ', message['data']);

  //       if (message && message['data'] && message['data'].comments) {
  //         this.comments$.next(message['data'].comments);
  //       }

  //     },
  //     (error) => {
  //       this.connectionState$.next(false);
  //       console.error('WebSocket error:', error);
  //     },
  //     () => {
  //       this.connectionState$.next(false);
  //       console.log('WebSocket connection closed');
  //     }
  //   );

  //   return this.socket$;
  // }

  public create(url: string): WebSocketSubject<any> {
    if (this.socket$ && !this.socket$.closed) {
      console.log('WebSocket is already connected.');
      return this.socket$;
    }

    this.socket$ = webSocket(url);

    // Subscribe to WebSocket events
    this.socket$.pipe(
      tap(() => {
        this.connectionState$.next(true)
      }),
      retryWhen(errors => errors.pipe(
        tap(err => {
          this.connectionState$.next(false);
          console.error('WebSocket error occurred:', err);
        }),
        delay(3000)
      )),
      catchError(error => {
        this.connectionState$.next(false);
        console.error('WebSocket error (caught):', error);
        return Rx.of(error);
      })
    ).subscribe(
      (message) => {
        console.log('Socket Connected!');
        if (message['error']) {
          this.handleServerError(message['error']); 
        }
        else if (message && message['data'] && message['data'].comments) {
          console.log('Socket Response: ', message['data'].comments);
          this.comments$.next(message['data'].comments);
        }
      },
      (error) => {
        console.error('WebSocket error:', error);
      },
      () => {
        console.log('WebSocket connection closed');
        this.connectionState$.next(false);
      }
    );

      return this.socket$
  }

  public sendMessage(message: any): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(message);
    } else {
      console.warn('Cannot send message: WebSocket is not connected.');
    }
  }


public disconnect(): void {
  if (this.socket$ && !this.socket$.closed) {
    this.socket$.complete(); 
    this.socket$ = undefined; 
    this.connectionState$.next(false);
    console.log('WebSocket disconnected.');
  } else {
    console.warn('Cannot disconnect: WebSocket is not connected.');
  }
}
public handleServerError(errorMessage: string) {
  console.error('Server error:', errorMessage);

  this.toasterService.error(errorMessage);
}


}
