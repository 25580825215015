import {Priority} from '@/Interface/priority';
import {StatusActive} from '@/Interface/statusActive';
import {TicketList} from '@/Interface/ticketList';
import {NocUserModel} from '@/model/nocUser';
import {PriorityModel} from '@/model/priority';
import {SelectPriorityModel} from '@/model/selectPriorityModel';
import {SelectUserModel} from '@/model/selectUserModel';
import {StatusActiveModel} from '@/model/statusActive';
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UtilityService} from '@services/utility.service';
import { WebsocketService } from '@services/websocket/websocket.service';
import {Subject, Subscription} from 'rxjs';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {
    ticketList: Array<TicketList> = [];
    pageSize: number;
    pageToGo: number = 1;
    searchElement = '';
    serialNumberArray: Array<number> = [];
    public pageSizeControl = new FormControl(10);
    ticketListbyid: TicketList;
    ticketListbyidarray: [] = [];
    statusType: string;
    priority = new PriorityModel();
    priortylist = new StatusActiveModel();
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    selectPriorityById = new SelectPriorityModel();
    getList: any;
    isAdmin: boolean;
    private latestQueryParams: any = {};
    loader: boolean = false;
    isDropdownClicked: boolean = false;

    sortColumn: string = 'Title'; // Default sorting column
    sortOrder: 'asc' | 'desc' = null; // Default sorting order
    sortOrderPlantName : 'asc' | 'desc' | null = null;
    sortOrderTicketName: 'asc' | 'desc' | null = null;


    private wsSubscription: Subscription = new Subscription();
    public connectionState: boolean = false;
    public ticket_id = 73;
    public user_id = 5

    selectUser = new SelectUserModel();
    user = new NocUserModel();
    statusDDL: any[] = [
        {id: 0, text: 'Unassigned '},
        {id: 1, text: 'InProcess'},
        {id: 2, text: 'Open'},
        {id: 3, text: 'On-Hold'},
        {id: 4, text: 'Assigned'},
        {id: 5, text: 'Closed'}
    ];
    public pageRecordOptions = [
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
        { value: '200', label: '200' },
      ];

    audioFormats: string[] = [
        'audio/mpeg', // MP3
        'audio/ogg', // Ogg Vorbis
        'audio/wav', // WAV
        'audio/aac', // AAC
        'audio/m4a', // M4A (MPEG-4 audio)
        'audio/flac', // FLAC (Free Lossless Audio Codec)
        'audio/x-ms-wma', // Windows Media Audio
        'audio/webm', // WebM audio
        'audio/3gp' // WebM audio
        // Add more audio formats and MIME types as needed
    ];

    ticektId!: any;
    ticketComments: ticketComments[] = [];
    comments: ticketComments[] = []

    newMessage: string;

    selectedFile: FileList | null = null;

    @ViewChild('fileInput') fileInput!: ElementRef;
    @ViewChild('audioPlayer') audioPlayer: ElementRef;

    //audio settings
    recording: boolean = false;
    audioURL: string = '';
    audioBlob: Blob | null = null;

    private mediaRecorder: MediaRecorder;
    private audioChunks: Blob[] = [];

    searchTerm: string;
    //audio settings end

    constructor(
        private _utiltiySevice: UtilityService,
        private modalService: NgbModal,
        private activatedRoute:ActivatedRoute,
        private router:Router,
        private webSocketService:WebsocketService
    ) {}

    ngOnInit(): void {
        // this.loader = true;
        this.newMessage = '';
        // this.getTicketList();
        this.getTableData();
        this.getPriority();
        this.getUser();
        this.getList = this._utiltiySevice.decodeUserCode();

        this.isAdmin = this.getList.superuser;
        // this.loader = false;

        //audio settings start
        // navigator.mediaDevices
        //   .getUserMedia({ audio: true })
        //   .then((stream) => {
        //     this.mediaRecorder = new MediaRecorder(stream);
        //     this.mediaRecorder.ondataavailable = (event) => {
        //       if (event.data.size > 0) {
        //         this.audioChunks.push(event.data);
        //       }
        //     };
        //     this.mediaRecorder.onstop = () => {
        //       this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        //       this.audioURL = URL.createObjectURL(this.audioBlob);
        //     };
        //   })
        //   .catch((error) => {
        //     console.error('Error accessing microphone:', error);
        //   });

        // audio settings end

    }

    // private disconnectWebSocket() {
    //     this.webSocketService.disconnect();
    //     this.wsSubscription.unsubscribe(); // Clean up subscriptions
    // }

    // ngOnDestroy() {
    //     this.disconnectWebSocket();
    //   }

    toggleDropdownClicked(): void {
        this.isDropdownClicked = !this.isDropdownClicked;
    }

    onSort(event: any): void {
      const sortBy = event.sorts[0];
      console.log('sortBy',sortBy)
      console.log('event',event)
      this.ticketList.sort((a, b) => {
        console.log('Object A:', a);
        console.log('Object B:', b);
        const valueA = a.hasOwnProperty(sortBy.prop) ? a[sortBy.prop] : null;
        const valueB = b.hasOwnProperty(sortBy.prop) ? b[sortBy.prop] : null;
          console.log('valueA',valueA)
          console.log('valueB',valueB)
  
          if (typeof valueA === 'number' && typeof valueB === 'number') {
              return sortBy.dir === 'asc' ? valueA - valueB : valueB - valueA;
          } else if (typeof valueA === 'string' && typeof valueB === 'string') {
              return sortBy.dir === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          } else {
              return String(valueA).localeCompare(String(valueB));
          }
      });
  }
//   private updateQueryParams(paramsToUpdate: any): void {
//     const queryParams = {
//         ...this.activatedRoute.snapshot.queryParams,
//         ...paramsToUpdate
//     };
//     console.log('Updated queryParams:', queryParams);

//     if (queryParams.search === '') {
//         queryParams.search = null;
//     }
//     if (queryParams.page === 1) {
//         queryParams.page = null;
//     }
    
//     if (queryParams.size === 10) {
//         queryParams.size = null;
//     }
//     this.router
//         .navigate([], {
//             relativeTo: this.activatedRoute,
//             queryParams: queryParams,
//             queryParamsHandling: 'merge'
//         })
//         .then(() => {
//             this.getTableData();
//         });
// }

//   private getTableData(): void {
//     const name =
//         this.activatedRoute.snapshot.queryParams['search'] ||
//         this.searchElement;
//     this.current_page = this.activatedRoute.snapshot.queryParams['page']
//         ? +this.activatedRoute.snapshot.queryParams['page']
//         : 1;
//     this.pageSize = this.activatedRoute.snapshot.queryParams['size']
//         ? +this.activatedRoute.snapshot.queryParams['size']
//         : 10;
//     this.getAllTickets(name, this.current_page, this.pageSize);
// }


//  Sorting as query params (backend)
sortTickets(column: string): void {
    if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
    }
  
    let sortValue: string | null = null;
    let columnName: string;
  
    if (this.sortOrder === 'asc') {
        sortValue = '0';
    } else if (this.sortOrder === 'desc') {
        sortValue = '1';
    }
  
    switch (column) {
        case 'plant_name':
            columnName = 'plant__stationName';
            this.sortOrderPlantName = this.sortOrder;
            this.sortOrderTicketName = null;
            break;
        case 'ticket_id':
            columnName = 'id';
            this.sortOrderPlantName = null;
            this.sortOrderTicketName = this.sortOrder;
            break;
        case 'source':
                columnName = 'source__name';
                this.sortOrderPlantName = null;
                this.sortOrderTicketName = this.sortOrder;
                break;
        case 'assigned_to':
                    columnName = 'assigned_to__username';
                    this.sortOrderPlantName = null;
                    this.sortOrderTicketName = null;
                    break;
        default:
            columnName = column;
            this.sortOrderPlantName = null;
            this.sortOrderTicketName = null;
            break;
    }
  
    this.updateQueryParams({ sort: sortValue, column: columnName });
}

  
private updateQueryParams(paramsToUpdate: any): void {
    this.latestQueryParams = {
        ...this.latestQueryParams,
        ...paramsToUpdate
    };

    if (this.latestQueryParams.search === '') {
        this.latestQueryParams.search = null;
    }
    if (this.latestQueryParams.page === 1) {
        this.latestQueryParams.page = null;
    }
    
    if (this.latestQueryParams.size === 10) {
        this.latestQueryParams.size = null;
    }
    if (this.latestQueryParams.sort === null) {
        delete this.latestQueryParams.sort;
    }
    
    if (this.latestQueryParams.column === null) {
        delete this.latestQueryParams.column;
    }
    // New condition integration
    if (this.latestQueryParams.page === 1 || (this.latestQueryParams.page && (this.latestQueryParams.search || this.latestQueryParams.department_id || this.latestQueryParams.designation_id))) {
        if (this.latestQueryParams.page > 1) {
            this.latestQueryParams.page = paramsToUpdate.page;
            if (this.latestQueryParams.size > 10) {
                this.latestQueryParams.size = paramsToUpdate.size;
            }
        } else {
            this.latestQueryParams.page = null;
        }
    } 

    if(this.latestQueryParams.page  > 1 && (this.latestQueryParams.size > 10 && this.latestQueryParams.page) ){
        this.latestQueryParams.page = null
    }


    this.getTableData();
}

private getTableData(): void {
    const name = this.latestQueryParams['search'] || this.searchElement;
    const page = this.latestQueryParams['page'] ? +this.latestQueryParams['page'] : 1;
    const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;
    const sort = this.latestQueryParams['sort'] || null;
    const column = this.latestQueryParams['column'] || null;
    this.getAllTickets(name, page, size,sort,column);
}

getAllTickets(name: string, page: number, size: number,sort:string | null,column:string | null): void {
    this.loader = true;
    this.ticketList = [];
    this.serialNumberArray = [];
    this._utiltiySevice
        .receiveAllTicketList(name, page, size,sort,column)
        .subscribe((res: any) => {
            if (res && res['status'] === 200) {
                this.ticketList = res.data.map((ticket: any, index: number) => ({
                    ...ticket,
                    srNo: (this.current_page - 1) * size + index + 1
                }));
                this.current_page = res.current_page;
                this.next_page = res.next_page;
                this.previous_page = res.previous_page;
                this.total_pages = res.total_pages;
                this.total_complaints = res.total_complaints;
                this.enablePagination();
            }
            this.loader = false;
        });
}
public searchData(event: Event): void {
    this.searchElement = (event.target as HTMLInputElement)?.value || '';
    if (this.searchElement.trim() === '') {
        this.updateQueryParams({search: null}); 
    } else {
        this.updateQueryParams({search: this.searchElement});
    }
}

goToPage(pageNo: number): void {
    if (pageNo >= 1 && pageNo <= this.total_pages) {
        this.updateQueryParams({page:pageNo});
    }
}

navigatePage(direction: string): void {
    if (direction === 'prev' && this.current_page > 1) {
        this.goToPage(this.current_page - 1);
    } else if (direction === 'next' && this.next_page !== null) {
        this.goToPage(this.current_page + 1);
    }
}

// Add a method to navigate to the entered page
navigateToPage(): void {
    if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
        this.goToPage(this.pageToGo);
    }
}

public changePageSize(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.pageSize = this.pageSizeControl.value!;
    this.updateQueryParams({size:this.pageSize})
    }
  
  
  

    getTicketList() {
        
        this.activatedRoute.queryParams.subscribe((params)=>{
            this.searchTerm = params['search'] || '';
        })
        //debugger;
        this._utiltiySevice.receiveTicketList(this.searchTerm).subscribe((res) => {
            if (res) {
                //debugger;
                this.ticketList = res.data;
                this.current_page = res.current_page;
                if (res.next_page == null) {
                    this.next_page = res.total_pages;
                } else {
                    this.next_page = res.next_page;
                }
                this.previous_page = res.previous_page;
                this.last_page = res.total_pages;
                this.total_pages = res.total_pages;

                this.enablePagination();
            }
        });
    }

    getFilterTicketList(filter: string) {
        this.loader = true;
        this._utiltiySevice
            .receiveFilteredTicketList(filter)
            .subscribe((res) => {
                if (res) {
                    //debugger;
                    this.ticketList = res.data;

                    this.current_page = res.current_page;
                    if (res.next_page == null) {
                        this.next_page = res.total_pages;
                    } else {
                        this.next_page = res.next_page;
                    }
                    this.previous_page = res.previous_page;
                    this.last_page = res.total_pages;
                    this.total_pages = res.total_pages;

                    this.enablePagination();
                    this.loader = false;
                }
            });
    }

    changeStatus(itemTicektList: TicketList, e) {
        this.priortylist.ticket_id = itemTicektList.getTicketID();
        this.priortylist.status = e;
        this._utiltiySevice.changeStatus(this.priortylist).subscribe((res) => {
            if (res) {
                // this.getTicketList();
                this.getTableData();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '`<h2>Ticket Status Successfully Updated</h2>`',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    }
    changePriority(itemTicektList: TicketList, e) {
        this.selectPriorityById.ticket_id = itemTicektList.getTicketID();
        this.selectPriorityById.priority = e;
        this._utiltiySevice
            .changePrioritySelect(this.selectPriorityById)
            .subscribe((res) => {
                if (res) {
                    // this.getTicketList();
                    this.getTableData();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '`<h2>Priority   Successfully Updated</h2>`',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
    }
    changeAssignTo(itemTicektList: TicketList, e) {
        this.selectUser.ticket_id = itemTicektList.getTicketID();
        this.selectUser.assigned_to = e;
        this._utiltiySevice
            .changeAssignToSend(this.selectUser)
            .subscribe((res) => {
                if (res) {
                    // this.getTicketList();
                    this.getTableData();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `<h2>Ticket Successfully Assign</h2>`,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
    }
    getPriority() {
        this._utiltiySevice.receivePreority().subscribe((res) => {
            if (res) {
                this.priority = res;
            }
        });
    }
    getUser() {
        this._utiltiySevice.receiveNocUser().subscribe((res) => {
            if (res) {
                this.user = res;
            }
        });
    }
    showTicketList(itemTicektList: TicketList, ticketDetail: any) {
        this.modalService.open(ticketDetail, {
            windowClass: 'complainModelClass'
        });
        // this.ticektId = itemTicektList.getTicketID();
        this.ticektId = itemTicektList.ticket_id;

        this._utiltiySevice.recvieTicketList(this.ticektId).subscribe((res) => {
            if (res) {
                this.ticketListbyid = res[0];
                this.statusType = this.statusDDL.find(
                    (x) => x.id === this.ticketListbyid.status
                )?.text;
                this.getTicketCOmments();

                // Socket Connection and fetching comments 
                // this.webSocketService.ticket_id = this.ticektId;
                // this.webSocketService.user_id = this.user_id

                // this.wsSubscription.add(
                // this.webSocketService.getConnectionState().subscribe((state) => {
                //     this.connectionState = state;
                // })
                // );
   
                // this.webSocketService.connect();

                // this.wsSubscription.add(
                //     this.webSocketService.getComments().subscribe((comments) => {
                //     //   this.comments = comments;
                //       this.ticketComments = comments;
                //       console.log('Comments received:', comments);
                //     })
                // );
            
            }
        });
    }

    getTicketCOmments() {
        this._utiltiySevice
            .getCommentsApi(this.ticektId)
            .subscribe((commentRes) => {
                if (commentRes) {
                    this.ticketComments = commentRes.data;
                    console.log('Ticket Comments: ', this.ticketComments)

                    console.log(this.ticektId);
                }
            });
    }

    statuToReopen(itemTicektList: TicketList) {
        this.priortylist.ticket_id = itemTicektList.getTicketID();
        this.priortylist.status = 0;
        this._utiltiySevice.changeStatus(this.priortylist).subscribe((res) => {
            if (res) {
                // this.getTicketList();
                this.getTableData();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '`<h2>Ticket Status Successfully</h2>`',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    }

    // ------------------------------------------------------------ Ticket List -------------------------------------------------------n
    next_page: number;
    current_page: number;
    filtered_page: number;
    previous_page: number;
    last_page: number;
    total_pages: number;
    total_complaints: number;

    enablePagination() {
        const linkElement = document.getElementById('prevPage');

        if (this.previous_page === null) {
            linkElement.setAttribute('disabled', 'true');
            linkElement.style.pointerEvents = 'none';
            linkElement.style.color = 'gray'; // You can set the color to indicate it's disabled
            linkElement.addEventListener('click', (event) => {
                event.preventDefault(); // Prevent the default behavior (navigation)
            });
        } else {
            linkElement.removeAttribute('disabled');
            linkElement.style.pointerEvents = 'auto'; // Enable clicking
            linkElement.style.color = ''; // Reset the color to its default
            linkElement.removeEventListener('click', (event) => {
                event.preventDefault();
            });
        }
    }

    Pagination(pageNo: number) {
        if (pageNo >= 1 && pageNo <= this.total_pages) {
            this.filtered_page = pageNo;
            this.getFilterTicketList(this.createfilter());
        }
    }

    createfilter(): string {
        var filter = '';
        // if(!(this.plantName  === null || this.plantName  == undefined))
        // {
        //     if(this.plantName !== "")
        //     {
        //         filter = filter + "search=" + this.plantName;
        //     }
        // }
        if (this.filtered_page >= 0) {
            if (filter !== '') {
                filter = filter + '&';
            }
            filter = filter + 'page=' + this.filtered_page;
        }

        // if(this.plantstatusfilter > 0)
        // {
        //     if(filter !== "")
        //     {
        //         filter = filter + '&';
        //     }
        //     filter = filter + "status=" + this.plantstatusfilter;
        // }

        // if(this.plantTypefilter > 0)
        // {
        //     if(filter !== "")
        //     {
        //         filter = filter + '&';
        //     }
        //     filter = filter + "type=" + this.plantTypefilter;
        // }

        return filter;
    }

    getTicketListFilter(filter: string) {
        // this._utiltiySevice.receiveTicketList().subscribe((res) => {
        //     if (res) {
        //         this.ticketList = res;
        //     }
        // });
    }

    matchCommentUser(userId) {
        var user_id = localStorage.getItem('userId');
        if (userId.toString() == user_id) {
            return true;
        } else {
            return false;
        }
    }

    openImageInNewWindow(imgUrl) {
        window.open(imgUrl, '_blank');
    }

    isImageUrl(text: string): boolean {
        // Regular expression to match common image file extensions
        const imageRegex = /\.(jpeg|jpg|gif|png|bmp|svg)$/i;
        return imageRegex.test(text);
    }

    isVideoFilePath(text: string): boolean {
        // List of valid video file extensions
        const videoExtensions = [
            '.mp4',
            '.mov',
            '.avi',
            '.mkv',
            '.wmv',
            '.flv'
        ];

        // Check if the text ends with a valid video file extension
        return videoExtensions.some((ext) => text.toLowerCase().endsWith(ext));
    }

    isMp3Url(url: string): boolean {
        // Regular expression to match common MP3 file extensions
        const audioRegex = /\.(mp3|ogg|wav|aac|m4a|flac|wma|webm|3gp)$/i;

        // Test if the URL matches the MP3 file extension pattern
        return audioRegex.test(url);
    }

    triggerFileInput() {
        this.fileInput.nativeElement.click();
    }

    sendMessage() {
        //debugger;
        console.log(this.selectedFile);
        if (this.newMessage != '') {
            this._utiltiySevice
                .addCommentsApi(
                    this.ticektId,
                    this.newMessage,
                    this.selectedFile,
                    1
                )
                .subscribe((res) => {
                    if (res) {
                        this.getTicketCOmments();
                        console.log(res);
                    }
                });
        } else {
            this._utiltiySevice
                .addCommentsApi(
                    this.ticektId,
                    this.newMessage,
                    this.selectedFile,
                    2
                )
                .subscribe((res) => {
                    if (res) {
                        this.getTicketCOmments();
                        console.log(res);
                    }
                });
        }
        this.newMessage = '';
    }

    onEmojiSelect(event: any) {
        // Add the selected emoji to the end of the newMessage string
        this.newMessage += event.emoji.native;
    }

    handleFileInput(event: any) {
        //debugger;

        // const fileList: FileList = event.target.files;
        // if (fileList.length > 0) {
        //   this.selectedFile = fileList;
        // } else {
        //   this.selectedFile = null;
        // }

        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files.length > 0) {
            this.selectedFile = inputElement.files;
        } else {
            this.selectedFile = null;
        }
    }

    uploadFile() {
        if (this.selectedFile) {
            // Perform your file upload logic here
            console.log('Uploading file:', this.selectedFile);
            // You can make an HTTP POST request to your server to handle the file upload.
            // You may want to use libraries like Angular HttpClient to do this.
        } else {
            console.log('No file selected.');
        }
    }

    toggleRecording() {
        if (this.recording) {
            this.mediaRecorder.stop();
            this.recording = false;
        } else {
            this.audioChunks = [];
            navigator.mediaDevices
                .getUserMedia({audio: true})
                .then((stream) => {
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            this.audioChunks.push(event.data);
                        }
                    };
                    this.mediaRecorder.onstop = () => {
                        this.audioBlob = new Blob(this.audioChunks, {
                            type: 'audio/wav'
                        });
                        this.audioURL = URL.createObjectURL(this.audioBlob);

                        // Debugging: Log the audioURL to check if it's correctly set
                        console.log('audioURL:', this.audioURL);

                        // Reload the audio element to update the controls
                        if (this.audioPlayer) {
                            this.audioPlayer.nativeElement.load();
                        }
                    };
                    this.mediaRecorder.start();
                    this.recording = true;
                })
                .catch((error) => {
                    console.error('Error accessing microphone:', error);
                });
        }
    }

    sendAudio() {
        if (this.audioBlob) {
            // Implement sending the audio data to your server using HttpClient
            // Example: Send audioBlob to a server
            // const formData = new FormData();
            // formData.append('audio', this.audioBlob, 'audio.wav');
            // this.httpClient.post('YOUR_SERVER_ENDPOINT', formData).subscribe((response) => {
            //   console.log('Audio sent successfully:', response);
            // });
        }
    }

    playAudio() {
        if (this.audioURL) {
            const audioElement = new Audio(this.audioURL);
            audioElement.play();
        }
    }

    // -------------------------------------------------------------------------------------------------------------------------------
}

export interface ticketComments {
    id: number;
    user_id: number;
    username?: string;
    comment?: string;
    ticket_id?: number;
    created_at?: string;
}
